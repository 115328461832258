// eslint-disable-next-line
// @ts-nocheck
import { i18n } from '@/lang/i18n';
import { pinia } from '@/store';
import { useCommonStore } from '@/store/common';
import { isPC } from '@/utils/tools';

const { t } = i18n.global;
const commonStore = useCommonStore(pinia);

// 通用路由 10个
const common = [
  {
    path: '/',
    component: () => import('@/pages/home/Index.vue'),
    meta: {
      layout: 'default',
      props: { header: { theme: 'dark', border: false }, footer: { theme: 'dark', border: false } },
      title: t('HashKey Global - 持牌数字资产交易所 - 安全可靠的数字资产交易平台 - 比特币，以太坊'),
      description: t('欢迎使用HashKey Global，您可在这里便捷地进行加密货币交易。作为备受认可的数字资产交易平台，我们以持牌合规为基础，为用户提供安全可靠的交易环境。通过HashKey Global，您将享受快捷、安全和便利的交易体验。'),
      ssg: {
        preload: [commonStore.getSymbols, commonStore.getCurrency]
      }
    },
    redirect: !isPC() ? '/h5/home' : ''
  },
  {
    path: '/h5/home',
    component: () => import('@/pages/home/hkh5/Index.vue'),
    meta: {
      layout: 'h5Default',
      title: t('HashKey Global - 持牌数字资产交易所 - 安全可靠的数字资产交易平台 - 比特币，以太坊'),
      description: t('欢迎使用HashKey Global，您可在这里便捷地进行加密货币交易。作为备受认可的数字资产交易平台，我们以持牌合规为基础，为用户提供安全可靠的交易环境。通过HashKey Global，您将享受快捷、安全和便利的交易体验。'),
      ssg: {}
    },
    redirect: !isPC() ? '' : '/'
  },
  {
    path: '/h5/agreement',
    component: () => import('@/pages/home/hkh5/Agreement.vue'),
    meta: {
      layout: 'h5Default',
      title: t('HashKey Global - 持牌数字资产交易所 - 安全可靠的数字资产交易平台 - 比特币，以太坊'),
      description: t('欢迎使用HashKey Global，您可在这里便捷地进行加密货币交易。作为备受认可的数字资产交易平台，我们以持牌合规为基础，为用户提供安全可靠的交易环境。通过HashKey Global，您将享受快捷、安全和便利的交易体验。'),
      ssg: {}
    }
  },
  {
    path: '/register',
    component: () => import('@/pages/register/Index.vue'),
    meta: {
      layout: 'login',
      title: t('现在注册｜HashKey - 买币更方便，存币更安心'),
      description: t('立即注册HashKey，开始进行加密货币交易，包括热门数字资产如比特币(BTC)和以太坊(ETH)。加入我们，尽享安全顺畅的交易体验。'),
      ssg: {}
    }
  },
  {
    path: '/register/invite',
    component: () => import('@/pages/register/invite/Invite.vue'),
    meta: {
      // 此页面为h5的被邀请人注册页面
      layout: 'h5Default',
      title: t('现在注册 - HashKey Global - 买币更方便，存币更安心'),
      description: t('立即注册HashKey，开始进行加密货币交易，包括热门数字资产如比特币(BTC)和以太坊(ETH)。加入我们，尽享安全顺畅的交易体验。'),
      ssg: {}
    },
    beforeEnter: (to, from, next) => {
      if (isPC()) {
        const params = to.params;
        const query = to.query;

        next({
          path: '/register',
          params: params,
          query: query
        });
      } else {
        next();
      }
    }
  },
  {
    path: '/h5/wx/download',
    component: () => import('@/pages/download/wechat/Index.vue'),
    meta: {
      layout: '',
      title: t('现在下载 - HashKey Global - 买币更方案，存币更安心'),
      description: t('下载HashKey Global应用程序，随时随地进行加密货币交易。在移动设备上享受无缝的交易体验。'),
      ssg: {}
    }
  },
  {
    path: '/download',
    component: () => import('@/pages/appDownload/web/Index.vue'),
    meta: {
      layout: 'default',
      props: { footer: { theme: 'dark', margin: false } },
      title: t('现在下载 App - HashKey Global - 买币更方便，存币更安心'),
      description: t('下载 HashKey Global 应用程序，随时随地进行加密货币交易。在移动设备上享受无缝的交易体验。'),
      ssg: {}
    },
    redirect: !isPC() ? '/h5/download' : ''
  },
  // {
  //   path: '/h5/imToken',
  //   component: () => import('@/pages/imToken/h5/Index.vue'),
  //   meta: {
  //     layout: 'h5Default',
  //     title: t('现在下载 - HashKey Global - 买币更方案，存币更安心'),
  //     description: t('下载HashKey Global应用程序，随时随地进行加密货币交易。在移动设备上享受无缝的交易体验。'),
  //     ssg: {}
  //   }
  // },
  {
    path: '/h5/download',
    component: () => import('@/pages/appDownload/h5/Index.vue'),
    meta: {
      layout: 'h5Default',
      title: t('现在下载 App - HashKey Global - 买币更方便，存币更安心'),
      description: t('下载 HashKey Global 应用程序，随时随地进行加密货币交易。在移动设备上享受无缝的交易体验。'),
      ssg: {}
    }
  },
  {
    path: '/login',
    component: () => import('@/pages/login/Index.vue'),
    meta: {
      layout: 'login',
      title: t('现在登录 - HashKey - 买币更方便，存币更安心'),
      description: t('安全登录 HashKey，开始进行数字加密货币交易：比特币(BTC)、以太坊(ETH)等热门加密货币的交易平台。'),
      ssg: {}
    }
  },
  {
    path: '/modify-password',
    component: () => import('@/pages/login/ModifyPassword.vue'),
    meta: {
      layout: 'login',
      title: t('修改密码'),
      ssg: {}
    }
  },
  {
    path: '/reset-password',
    component: () => import('@/pages/reset-password/Layout.vue'),
    meta: {
      layout: 'login',
      title: t('忘记密码'),
      ssg: {}
    }
  },
  {
    path: '/auth',
    component: () => import('@/pages/auth/Index.vue'),
    meta: {
      layout: '',
      title: t('现在登录 - HashKey Global - 买币更方案，存币更安心'),
      ssg: {}
    }
  },
  {
    path: '/official-verification',
    component: () => import('@/pages/official-verification/web/Index.vue'),
    meta: {
      layout: 'default',
      title: t('HashKey 官方渠道验证'),
      ssg: {}
    },
    redirect: !isPC() ? '/h5/official-verification' : ''
  },
  {
    path: '/h5/official-verification',
    component: () => import('@/pages/official-verification/h5/Index.vue'),
    meta: {
      layout: 'h5Default',
      title: t('HashKey 官方渠道验证'),
      ssg: {}
    },
    redirect: !isPC() ? '' : '/official-verification'
  },
  {
    path: '/support-fee',
    component: () => import('@/pages/support/feeIndex.vue'),
    meta: {
      layout: 'default',
      title: t('support-fee.page-title'),
      props: {
        header: {
          class: 'm-width-1440 mobile-header'
        },
        default: {
          isPC: true
        },
        footer: {
          class: 'm-width-1440 mobile-footer'
        }
      },
      ssg: {}
    },
    redirect: !isPC() ? '/h5/support-fee' : ''
  },
  {
    path: '/h5/support-fee',
    component: () => import('@/pages/support/feeIndex.vue'),
    meta: {
      layout: 'h5Default',
      title: t('support-fee.page-title'),
      ssg: {}
    },
    props: {
      default: {
        isPC: false
      }
    },
    redirect: isPC() ? '/support-fee' : ''
  }
];

export default common;