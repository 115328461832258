import { isClient } from '@vueuse/shared';
import { i18n } from '@/lang/i18n';

const { t } = i18n.global;
// const { t } = useI18n();
export default {
  CNY: '￥',
  UST: '$',
  // 行情页model/ws数据更新频率
  refresh_ws: 1600,
  // 当前币对 刷新频率 ms，
  refresh: 200,
  // 深度刷新频率
  refresh_depth: 1000,
  // 行情页token列表刷新频率
  refresh_tokens: 500,
  // 盘口刷新频率
  refresh_handicap: 500,
  // 最新成交刷新频率
  refresh_trade: 100,
  refresh_150: 200,
  // 深度精度值，"0"跟K线价格精度0区别
  depth: {
    100000000: -8,
    10000000: -7,
    1000000: -6,
    100000: -5,
    10000: -4,
    1000: -3,
    100: -2,
    10: -1,
    1: 0,
    0: 0,
    0.1: 1,
    0.01: 2,
    0.001: 3,
    0.0001: 4,
    0.00001: 5,
    0.000001: 6,
    '0.0000001': 7,
    '0.00000001': 8,
    '0.000000001': 9,
    '0.0000000001': 10,
    '0.00000000001': 11,
    '0.000000000001': 12
  },
  // k线价格精度
  k: {
    0: 1,
    1: 10,
    2: 100,
    3: 1000,
    4: 10000,
    5: 100000,
    6: 1000000,
    7: 10000000,
    8: 100000000,
    9: 1000000000,
    10: 10000000000,
    11: 100000000000,
    12: 1000000000000
  },
  // 最新成交展示条数
  trade_limit: 50,
  rowsPerPage: 10,
  rowsPerPage1: 20,
  rowsPerPage2: 50,
  rowsPerPageOptions: [5, 10, 20],
  minLimit: 100,
  maxLimit: 200,
  totalLimit: 1000,
  // img host
  imghost: 'https://static.applecex.com',
  // 同盾指纹
  TDUrl: 'https://static.trustdecision.com/tdfp/sg/5b857e16f08f93a5fd693a7ddf81d15a/fm.js',
  TDPartnerCode: 'HashKey',
  TDPartnerKey: '90ded16d51fa4c6cb01b078f06d823f8',
  TDAppName: 'HashKey_web',
  // ipqs url
  ipqsUrl: 'https://www.ipqscdn.com/api/*/i6uV5Kd4GqHcK5cv8PjBTtd2twajjxtUfbgje1IlZYRoEHU28ylvThyLrUP1Wti34EvlStDbL4jzWIsj0En7bA5lcnQQDYqq84G0mw459b9KGmusAKnA5gMwYFmcbrOSTZGE3DFyCkqEUAnYRBrpl9gCDnG76xBD0TMna5vFKY6LPSixEQUMnRbQymbGdRMSmFXlP0FkvS2W29YqmU4lrCv8d0FxqxiTkyDzP2dlaMCK8KAemoCNUdV8aTZNUn99/learn.js',
  // ipqs secretKey
  ipqsSecretKey: 'i6uV5Kd4GqHcK5cv8PjBTtd2twajjxtUfbgje1IlZYRoEHU28ylvThyLrUP1Wti34EvlStDbL4jzWIsj0En7bA5lcnQQDYqq84G0mw459b9KGmusAKnA5gMwYFmcbrOSTZGE3DFyCkqEUAnYRBrpl9gCDnG76xBD0TMna5vFKY6LPSixEQUMnRbQymbGdRMSmFXlP0FkvS2W29YqmU4lrCv8d0FxqxiTkyDzP2dlaMCK8KAemoCNUdV8aTZNUn99',
  // charting_library
  charting_library_url: '/static/charting_library/charting_library.standalone.js',
  // gt4.js
  gt4Url: 'https://static.geetest.com/v4/gt4.js',
  // google client
  googleClientUrl: 'https://accounts.google.com/gsi/client',
  // google client id
  googleClientId: '195398356877-etd3pfilv45hsr46ivnn4odfvkg8uevp.apps.googleusercontent.com',
  // apple auth.js
  appleAuthUrl: 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
  // apple client id
  appleClientId: 'com.hashkey.global_login',
  // 合约状态
  optionStatus: {
    SETTLE_NO: 0,
    SETTLE_DOING: 1,
    SETTLE_DONE: 2
  },
  // 永续合约杠杆小数位
  lever_decimal: 2,
  fee: 0.005,
  // 保证金率保留8位小数
  initialMargin: 8,
  // 永续合约买卖方向
  sideMap: {
    BUY: '买入',
    SELL: '卖出',
    BUY_OPEN: '买入开多',
    SELL_OPEN: '卖出开空',
    BUY_CLOSE: '买入平空',
    SELL_CLOSE: '卖出平多'
  },
  kline_type_hb: ['1', '5', '15', '30', '60', '240', '1440', '10080', '44640'],
  kline_type_map_hb: {
    '1m': '1min',
    '5m': '5min',
    '15m': '15min',
    '30m': '30min',
    '1h': '60min',
    '4h': '4hour',
    '1d': '1day',
    '1w': '1week',
    '1M': '1mon'
  },
  kline_btns_hb: [
    {
      slug: 'Time', // 窄屏展示
      resolution: '1', // 传给K线的参数
      chartType: 3, // 图表类型
      res: 'Time', // 区分分时与1m
      full: 'Time' // 宽屏展示
    },
    {
      slug: '1m',
      resolution: '1',
      res: '1',
      option: [
        {
          resolution: '1',
          slug: '1m',
          full: '1min',
          res: '1'
        },
        {
          resolution: '5',
          slug: '5m',
          full: '5min',
          res: '5'
        },
        {
          resolution: '15',
          slug: '15m',
          full: '15min',
          res: '15'
        },
        {
          resolution: '30',
          slug: '30m',
          full: '30min',
          res: '30'
        }
      ]
    },
    {
      slug: '1H',
      resolution: '60',
      res: '60',
      option: [
        {
          resolution: '60',
          slug: '1H',
          full: '1hour',
          res: '60'
        },

        {
          resolution: '240',
          slug: '4H',
          full: '4hour',
          res: '240'
        }
      ]
    },
    {
      slug: '1D',
      resolution: '1440',
      res: '1440',
      full: '1day'
    },
    {
      slug: '1W',
      resolution: '10080',
      res: '10080',
      full: '1week'
    },
    {
      slug: '1M',
      resolution: '44640',
      res: '44640',
      full: '1mon'
    }
  ],
  kline_btns: [
    {
      slug: 'Time', // 窄屏展示
      resolution: '1', // 传给K线的参数
      chartType: 3, // 图表类型
      res: 'Time', // 区分分时与1m
      full: 'Time' // 宽屏展示
    },
    {
      resolution: '1',
      slug: '1m',
      full: '1min',
      res: '1'
    },
    // {
    //   resolution: '3',
    //   slug: '3m',
    //   full: '3min',
    //   res: '3'
    // },
    {
      resolution: '5',
      slug: '5m',
      full: '5min',
      res: '5'
    },
    {
      resolution: '15',
      slug: '15m',
      full: '15min',
      res: '15'
    },
    {
      resolution: '30',
      slug: '30m',
      full: '30min',
      res: '30'
    },
    {
      resolution: '60',
      slug: '1H',
      full: '1hour',
      res: '60'
    },
    {
      resolution: '240',
      slug: '4H',
      full: '4hour',
      res: '240'
    },
    {
      slug: '1D',
      resolution: '1440',
      res: '1440',
      full: '1day'
    },
    {
      slug: '',
      resolution: '60',
      res: '60',
      option: [
        {
          resolution: '15',
          slug: '15m',
          full: '15min',
          res: '15'
        },
        {
          resolution: '30',
          slug: '30m',
          full: '30min',
          res: '30'
        },
        {
          resolution: '60',
          slug: '1H',
          full: '1hour',
          res: '60'
        },
        {
          slug: '1D',
          resolution: '1440',
          res: '1440',
          full: '1day'
        },
        {
          resolution: '120',
          slug: '2H',
          full: '2hour',
          res: '120'
        },
        {
          resolution: '240',
          slug: '4H',
          full: '4hour',
          res: '240'
        },
        {
          resolution: '360',
          slug: '6H',
          full: '6hour',
          res: '360'
        },
        // {
        //   resolution: "480",
        //   slug: "8H",
        //   full: "8hour",
        //   res: "480",
        // },
        {
          resolution: '720',
          slug: '12H',
          full: '12hour',
          res: '720'
        },
        {
          slug: '1W',
          resolution: '10080',
          res: '10080',
          full: '1week'
        }
        // {
        //   slug: '1M',
        //   resolution: '44640',
        //   res: '44640',
        //   full: '1mon'
        // }
      ]
    }
  ],
  kline_type: [
    '1',
    '3',
    '5',
    '15',
    '30',
    '60',
    '120',
    '240',
    '360',
    '480',
    '720',
    '1440',
    '10080',
    '44640'
  ],
  kline_type_tv: [
    '1',
    '3',
    '5',
    '15',
    '30',
    '60',
    '120',
    '240',
    '360',
    '480',
    '720',
    '1D',
    '1W',
    '1M'
  ],
  kline_type_tv_map: {
    '1D': '1440',
    '1W': '10080',
    '1M': '44640'
  },
  kline_type_tv_map_reverse: {
    '1440': '1D',
    '10080': '1W',
    '44640': '1M'
  },
  REGIST_TYPE: {
    MOBILE: 1,
    EMAIL: 2
  },
  REGISTER_OPTIONS: {
    ALL: 1,
    ONLY_PHONE: 2,
    ONLY_EMAIL: 3,
    EMAIL_AND_CHINA_PHONE: 4
  },
  time_in_force: 'GTC',

  REPAY_WAY: {
    PRINCIPAL: 1, // 还本金
    ALL: 2 // 还币还息
  },
  // 个性化配置默认值
  customConfig: {
    // 闪电平仓
    quickCloseConfirm: true,
    // 默认语言
    lang: isClient ? window.localStorage.lang : '',
    // 默认法币
    unit: isClient ? window.localStorage.unit : '',
    // 行情颜色方案,  0 = 绿涨红跌; 1 = 红涨绿跌
    up_down: 0
  },

  ORDER_TYPE: {
    LIMIT: 'limit',
    MARKET: 'market',
    PLAN: 'plan'
  },

  ENTRUST_TYPE: {
    NORMAL: 'LIMIT,MARKET,LIMIT_MAKER', // 普通委托
    CONDITION: 'CONDITION,CONDITION_LIMIT', // 条件单
    STOP_LOSS: 'CONDITION,CONDITION_LIMIT&' // 止盈止损,接口传参需要把&舍弃
  },

  RECEIVER_TYPE: {
    MOBILE: 1,
    EMAIL: 2
  },

  CONVERT: {
    PRICE_TYPE: {
      STATIC: 1, // 固定价格
      FLOAT: 2 // 浮动价格
    }
  },

  AUTH_TYPE: {
    MOBILE: 1, // 手机
    EMAIL: 2, // 邮箱
    GA: 3 // GA
  },

  // 验证码方式
  CODE_TYPE: {
    LOGIN: 2,
    BIND_MOBILE: 5,
    BIND_EMAIL: 6,
    BIND_GA: 7,
    CREATE_API_KEY: 11,
    CHANGE_API_KEY: 12,
    DELETE_API_KEY: 13,
    UNBIND_EMAIL: 17,
    UNBIND_MOBILE: 18,
    UNBIND_GA: 19,
    FISH_CODE: 24,
    CHANGE_BIND_EMAIL: 25,
    CHANGE_BIND_MOBILE: 26,
    CHANGE_BIND_GA: 27
    // CHANGE_BIND_EMAIL_NEW: 28, // 增加了二次验证
    // CHANGE_BIND_MOBILE_NEW: 29, // 增加了二次验证
  },

  //
  ACCOUNT_TYPE: {
    // 这里是原定义的枚举，未搜到代码引用，直接修改其枚举值
    // COIN: 0,
    // MARGIN: 27,
    // REWARD: 42 // 活动账户

    MAIN: 1, // 主账户
    OPTION: 2, // 期权账户
    FUTURES: 3, // 期货账户
    COPY_TRADING: 4, // 跟单子账户，不是真是存在的账户
    CUSTODY: 5, // 资金账户
    FIAT: 6, // 法币账户
    OPT: 7, // opt账户
    OPT_DISPOSAL: 8, // opt处置资产账户
    OMNIBUS: 9, // 预存金账户
    MARGIN: 27 // 杠杠账户

  },

  TOKEN_TYPE: {
    COIN: 1,
    CONTRACT: 2
  },
  INVITE_TYPE: {
    DIRECT: 1,
    INDIRECT: 2
  },
  ORDER_STATUS: {},
  // 条件单触发类型
  TRIGGER_TYPE: {
    NO_TRIGGER: 0,
    MORE: 1,
    LESS: 2
  },
  // 合约仓位类型
  POSITION_TYPE: {
    ISOLATE: 0,
    MERGE: 1
  },
  // 合约仓位索引
  POSITION_INDEX: {
    LONG: 0,
    SHORT: 1,
    MERGE: 2
  },
  // 合约仓位方向
  POSITION_SIDE: {
    MERGE: 0,
    OPEN: 1,
    CLOSE: 2
  },
  // 合约保证金类型
  MARGIN_TYPE: {
    PART: 0,
    WHOLE: 1
  },
  CONTRACT_TYPE: {
    1: 'linear',
    2: 'swap',
    3: 'contract'
  },
  ACCOUNT_PURPOSE: {
    INVESTMENT: 1,
    TRADING: 2
  },

  KYC_TYPE: {
    UNDEFINED: 0,
    PERSON: 1,
    INSTITUTIONS: 2
  },

  KYC_SYNC_TYPE: {
    GLOBAL: 1,
    SG: 2
  },

  MARGIN_ACTIVITY_ERROR_TYPE: {
    NOT_START: 30050,
    NO_LOAN: 80028,
    NO_ASSETS: 33001,
    NEED_KYC2: 31517,
    EXCEED_LIMIT: 80029
  },

  CAPTCHA_TYPES: {
    GOOGLE: 1,
    SENSE: 2,
    SENSE_PRO: 3
  },
  CAPTCHA_TYPE: 1,
  SITE_KEY: '6Ldk7FUbAAAAAAiLi0buVGyjZ2x_4YhLxcqhjV29', // google_captchaId
  SENSE_ID: '', // 极验的id
  SENSE_PRO_ID: '1b140c59a6da8e906cb4b7ca6687e075', // 深知pro的id
  DEFAULT_NATIONAL_CODE: 65,
  KycTotalStatusEnum: {
    TOTAL_STATUS_UNKNOWN: 0,
    PENDING: 1,  // 待提交审核
    REVIEW: 2, // 审核中
    PASS: 3, // 审核通过
    REJECTED: 4, // 审核拒绝
    SUSPEND: 5, // Verified客户被重置，需要重新提交验证
    PENDING_UPDATE: 6, // 即将过期
    PENDING_VERIFYING: 7, // 即将过期审核中
    PENDING_REJECTED: 8, // 即将过期驳回
    OVERDUE: 9, // 已过期
    PENDING_RESET: 10 // 即将过期重置
  },
  KycMaterialAuditStatusEnum: {
    MATERIAL_STATUS_UNKNOWN: 0,
    MATERIAL_STATUS_EMPTY: 1, // 没填资料
    MATERIAL_STATUS_INVALID: 2, // 材料有错误
    MATERIAL_STATUS_PENDING: 3, // 已保存待提交
    MATERIAL_STATUS_REVIEW: 4, // 审核中
    MATERIAL_STATUS_PASS: 5, // 审核通过
    MATERIAL_STATUS_REJECT: 6, // 审核拒绝
    MATERIAL_STATUS_NOT_PASS: 7, // 风险等级不通过
    MATERIAL_STATUS_SUBMITTING: 8, // 提交中
    MATERIAL_STATUS_COMPLETED: 9, // 已完成可修改
    MATERIAL_STATUS_COMPLETED_UNCHANGEABLE: 10 // 已完成且不可修改
  },
  kycUpdateType: {
    1: [1], // 姓名（英文）
    2: [2], // 姓名（本地）
    3: [3], // 出生日期
    4: [1, 3], // 姓名（英文） + 出生日期
    5: [2, 3] // 姓名（本地） + 出生日期
  },
  kycStatus: {
    TOTAL_STATUS_UNKNOWN: 0,  // 待提交
    PENDING: 1, // 待审核
    PASS: 2, // 审核通过
    REJECTED: 3 // 审核拒绝
  },
  riskLevel: {
    C1: t('保守型'),
    C2: t('稳健型'),
    C3: t('平衡型'),
    C4: t('积极型'),
    C5: t('激进型')
  },
  gender: [
    {
      value: '1',
      label: t('男')
    },
    {
      value: '2',
      label: t('女')
    }
  ],
  cardList: [
    {
      value: '1',
      label: t('身份证')
    },
    {
      value: '2',
      label: t('护照')
    }
  ],
  overseasCardList: [
    {
      value: '1',
      label: t('工作/学生签证等 ')
    },
    {
      value: '2',
      label: t('海外身份证')
    }
  ],
  addressType: [
    {
      value: '1',
      label: t('水费及电费账单')
    },
    {
      value: '2',
      label: t('银行对账单')
    },
    {
      value: '3',
      label: t('其他')
    }
  ],
  option: [
    {
      value: '0',
      label: t('是')
    },
    {
      value: '1',
      label: t('否')
    }
  ],
  actionList: [
    {
      value: '0',
      label: t('不，我不是美国⼈')
    },
    {
      value: '1',
      label: t('是的，我是美国⼈')
    }
  ],
  jobList: [
    {
      value: '1',
      label: t('公司员工')
    },
    {
      value: '2',
      label: t('政府工作人员')
    },
    {
      value: '3',
      label: t('公司高管')
    },
    {
      value: '4',
      label: t('学生')
    },
    {
      value: '5',
      label: t('其他')
    }
  ],
  sourcesoffinanceList: [
    {
      value: '1',
      label: t('薪资')
    },
    {
      value: '2',
      label: t('收入积蓄')
    },
    {
      value: '3',
      label: t('佣金/营业收入')
    },
    {
      value: '4',
      label: t('退休金/准备金')
    },
    {
      value: '5',
      label: t('投资收入')
    },
    {
      value: '6',
      label: t('其他')
    }
  ],
  natureList: [
    {
      value: '1',
      label: t('自雇人士/企业家')
    },
    {
      value: '2',
      label: t('政府雇员')
    },
    {
      value: '3',
      label: t('金融服务')
    },
    {
      value: '4',
      label: t('资讯科技')
    },
    {
      value: '5',
      label: t('销售/顾问')
    },
    {
      value: '6',
      label: t('艺术家/媒体')
    },
    {
      value: '7',
      label: t('数字资产行业')
    },
    {
      value: '8',
      label: t('其他')
    }
  ],
  empStatusList: [
    {
      value: '1',
      label: t('受雇')
    },
    {
      value: '2',
      label: t('自雇')
    },
    {
      value: '3',
      label: t('退休')
    },
    {
      value: '4',
      label: t('学生')
    },
    {
      value: '5',
      label: t('家庭主妇')
    },
    {
      value: '6',
      label: t('未受雇')
    }
  ],
  rankList: [
    {
      value: '1',
      label: t('管理人员')
    },
    {
      value: '2',
      label: t('普通员工')
    }
  ],
  purposeList: [
    {
      value: '1',
      label: t('资本增长')
    },
    {
      value: '2',
      label: t('投机')
    },
    {
      value: '3',
      label: t('对冲')
    },
    {
      value: '4',
      label: t('赚取收入')
    }
  ],
  highestEducationLevel: [
    {
      value: '1',
      label: t('小学')
    },
    {
      value: '2',
      label: t('中学')
    },
    {
      value: '3',
      label: t('大专')
    },
    {
      value: '4',
      label: t('本科或以上')
    }
  ],
  totalNetWorth: [
    {
      value: '1',
      label: '0M ~ 2M'
    },
    {
      value: '2',
      label: '2M ~ 5M'
    },
    {
      value: '3',
      label: '5M ~ 10M'
    },
    {
      value: '4',
      label: '10M+'
    }
  ],
  // 法币-账户历史
  FIAT_ORDER_TYPE: {
    DEPOSIT_SCB: '6', // 充值-快速转账 [页面使用，不往后台传该值]
    DEPOSIT_ZA: '1', // 充值-常规转账 -> 旧接口
    WITHDRAW_SCB: '7', // 提现-快速转账 [页面使用，不往后台传该值]
    WITHDRAW_ZA: '2', // 提现-常规转账 -> 旧接口
    TRANSFER: '3' // 划转 -> 旧接口
  },
  // 充值地址类型
  DEPOSIT_ADDRESS_TYPE: {
    PERSONAL_ADDRESS: 'personal_address',
    EXCHANGE_ADDRESS: 'exchange_address'
  },
  // 账户类型   1. 零售 2. 企业 3. 个人（PI） 4. 综合账户 5. 交易员子账号 6. 授权交易员 7.临时身份 8.金融机构
  USER_TYPE: {
    UNKNOWN: 0, // 新注册用户
    RETAIL: 1, // 零售
    CORPORATE: 2, // 企业
    PERSONAL_PI: 3, //  个人（PI）
    OMNIBUS: 4, // 综合账户
    TRADER_SUB_ACCOUNT: 5, // 交易员子账号
    AUTHORIZED_TRADER: 6, // 授权交易员
    TEMPORARY_ID: 7, // 临时身份
    INSTITUTION: 8, // 金融机构
    CORPORATEMM: 10, // 企业 Market Maker
    RETAILMM: 9 // 个人 Market Maker
  },
  // zendesk文档中心的domain
  ZENDESK_DOMAIN: 'https://support.global.hashkey.com/',
  MEDIA_URL: {
    Twitter: {
      'zh-CN': 'https://twitter.com/HashKey_Global',
      'zh-TW': 'https://twitter.com/HashKey_Global',
      'en-US': 'https://twitter.com/HashKey_Global'
    },
    Telegram: {
      'zh-CN': 'https://t.me/HashKey_Global',
      'zh-TW': 'https://t.me/HashKey_Global',
      'en-US': 'https://t.me/HashKey_Global'
    },
    Instagram: {
      'zh-CN': 'https://www.instagram.com/hashkey_global_',
      'zh-TW': 'https://www.instagram.com/hashkey_global_',
      'en-US': 'https://www.instagram.com/hashkey_global_'
    },
    WhatsApp: {
      'zh-CN': 'https://chat.whatsapp.com/JSepzblEUkUCxkBQdzDGep',
      'zh-TW': 'https://chat.whatsapp.com/JSepzblEUkUCxkBQdzDGep',
      'en-US': 'https://chat.whatsapp.com/JSepzblEUkUCxkBQdzDGep'
    },
    Discord: {
      'zh-CN': 'https://discord.gg/hashkeyglobal',
      'zh-TW': 'https://discord.gg/hashkeyglobal',
      'en-US': 'https://discord.gg/hashkeyglobal'
    }
  },
  // 首页hashkey新闻
  NEWS_URL: {
    New1: {
      'zh-CN': 'https://blog.h.xyz/en-us/hashkey-follows-coinbase-in-opening-global-exchange-in-bermuda/',
      'zh-TW': 'https://blog.h.xyz/en-us/hashkey-follows-coinbase-in-opening-global-exchange-in-bermuda/',
      'en-US': 'https://blog.h.xyz/en-us/hashkey-follows-coinbase-in-opening-global-exchange-in-bermuda/'
    },
    New2: {
      'zh-CN': 'https://blog.h.xyz/en-us/hashkeys-crypto-exchange-goes-live-after-winning-license-in-bermuda/',
      'zh-TW': 'https://blog.h.xyz/en-us/hashkeys-crypto-exchange-goes-live-after-winning-license-in-bermuda/',
      'en-US': 'https://blog.h.xyz/en-us/hashkeys-crypto-exchange-goes-live-after-winning-license-in-bermuda/'
    },
    New3: {
      'zh-CN': 'https://www.bloomberg.com/news/videos/2024-04-08/hashkey-global-md-on-new-crypto-exchange-video',
      'zh-TW': 'https://www.bloomberg.com/news/videos/2024-04-08/hashkey-global-md-on-new-crypto-exchange-video',
      'en-US': 'https://www.bloomberg.com/news/videos/2024-04-08/hashkey-global-md-on-new-crypto-exchange-video'
    }
  }
} as any;

interface INumObj {
  [key: number]: string
}

export const tradingStatus: INumObj = {
  2: '开盘倒计时',
  // 2: '即将开盘',
  4: '交易停牌',
  5: '交易停牌',
  6: '交易停牌',
  7: '即将复牌'
};

export const ADDRESS_RECORD_TYPE = {
  PERSONAL: '0', // 个人
  THIRD: '1' // 第三方
};
// 存管订单 在列表中显示"待认证" 1
export const ORDER_TO_VERIFY_STATUS = 1;
// 存管订单 在列表中显示"查看详情" 9, 11
// 11目前为国际站独有
export const ORDER_VIEW_DETAIL_STATUS = [9, 11];
// 存管充值订单 退款中(5) 显示"联系客服"
export const ORDER_CONTRACT_CUSTOMER = 5;
// 上账中
export const ORDER_DEPOSITING = 8;
// 存管充值订单 充值成功
export const ORDER_DEPOSIT_SUCCESS = 4;
// API 权限 列表
export const API_PERMISSION:{[key: string]: string} = {
  '2': '只读权限',
  '5': '读写-允许提币',
  '7': '读写-允许现货交易',
  '3': '读写-允许合约交易',
  '8': 'write_api_allow_internal_spot_transfer',
  '6': '读写-允许第三方充值',
  '10': 'write_api_allow_internal_concat_transfer'
};

export type KYC_COUNTRY_CONFIG_NAME = {
  ID_AS_POA_COUNTRY_LIST: 'ID_AS_POA_COUNTRY_LIST', // 可跳过上传地址证明的国家列表
  ID_ADDITIONAL_NUMBER_COUNTRY_LIST: 'ID_ADDITIONAL_NUMBER_COUNTRY_LIST', // 日本身份证的唯一不变的证件号码其实是在身份证背面的12位号码
  LOCAL_NAME_COUNTRY_LIST: 'LOCAL_NAME_COUNTRY_LIST', // 开启本地姓名的国家列表 | BMEX-3246
  // COUPLE_NAME_COUNTRY_LIST: 'COUPLE_NAME_COUNTRY_LIST' // 展示两套名字的国家列表 | BMEX-3246
  KYC_COUPLE_NAME_COUNTRY_CONFIG: 'KYC_COUPLE_NAME_COUNTRY_CONFIG' // 新增展示两套名字的国家列表和相应的证件类型 | BMEX-4461139
}

// BMEX-3102 修改资产相关验证方式，提高GA权重
export const enum AUTH_SAFE_CUSTODY_LEVEL {
  NO_BIND_GA = -1,
  BIND_GA = 1, // 理论上不会出现只绑定了GA的情况, 保留1用于和原计算逻辑一致
  BIND_EMAIL_OR_MAIL = 2,
  BIND_ALL = 3
}

export const enum AUTH_SAFE_MODE {
  CUSTODY = 'custody',
  COMMON = 'common'
}
// 取消提现处理中
export const CANCEL_WITHDRAW_DOING = '100';
// 提现中
export const WITHDRAW_DOING = '0';
export const NEW_USER_TASK = {
  KYC_DURING_EVENT: 1, // 在活动期间内完成KYC
  FIRST_RECHARGE_IN_PROGRESS: 1, // 完成充值发送中
  FIRST_RECHARGE_COMPLETED: 2, // 完成充值已发送
  FIRST_RECHARGE_NOT_COMPLETED: 3, // 未完成充值
  FIRST_RECHARGE_NOT_DURING_EVENT: 4, // 非活动期间完成充值
  FIRST_TRADE_IN_PROGRESS: 1, // 完成交易发送中
  FIRST_TRADE_COMPLETED: 2, // 完成交易已发送
  FIRST_TRADE_NOT_COMPLETED: 3, // 未完成交易
  FIRST_TRADE_NOT_DURING_EVENT: 4 // 非活动期间完成交易

};

export const CATIZEN_URL: Record<string, string> = {
  'zh-CN': 'https://support.global.hashkey.com/hc/zh-cn/articles/15822007036444',
  'zh-TW': 'https://support.global.hashkey.com/hc/zh-tw/articles/15822007036444',
  'en-US': 'https://support.global.hashkey.com/hc/en-us/articles/15822007036444'
};

export const EXPORT_STATUS_DESC = {
  ['EXPORTING']: t('生成中'),
  ['EXPORT_SUCCESS']: t('已完成'),
  ['EXPORT_EXPIRED']: t('已发放'),
  ['EXPORT_FAILED']: t('已失败')
};

export const MAX_LEVER = 20;
