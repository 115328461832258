import moment from 'moment';
import { isApp } from '@/utils/appBridge';
import { useAppStore } from '@/store/app';
// import utc from 'moment/plugin/utc';

// moment.extend(utc);
/**
 * 格式化为utc展示时间
 *
 * @param {number|string} time 时间戳
 * @param {string} format 格式化方式
 * @return {string} utc时间展示
 */
export function formatUTCTime(time, format = 'YYYY-MM-DD HH:mm:ss') {
  return `${moment.utc(Number(time)).format(format)}`;
}

/**
 *
 * 格式化为本地展示时间
 *
 * @param {number|string} time 时间戳
 * @param {string} format 格式化方式
 * @return {string} 本地时间展示
 */
export function formatLocalTime(time, format = 'YYYY-MM-DD HH:mm:ss') {
  return `${moment.utc(Number(time)).local().format(format)}`;
}

/**
 * 根据给定的时间和格式格式化时间。
 * 如果在App中，会根据应用是否支持时区调整来选择是使用本地时间还是UTC时间进行格式化。
 *
 * @param {String|Number} time - 需要格式化的时间，可以是时间戳或日期字符串。
 * @param {String} format - 时间格式，默认为'YYYY-MM-DD HH:mm:ss'
 * @param {String} extra - 额外的文字，会在时间字符串后面追加。
 * @returns {String} 格式化后的时间字符串，如果指定了extra，则结果为时间字符串加上extra。
 */
export function formatTime(time, format = 'YYYY-MM-DD HH:mm:ss', extra = '') {
  let result = '';
  // 判断当前是否在应用环境中
  if (isApp) {
    // 检查应用是否支持时区调整
    const appStore = useAppStore();
    const support = appStore.supportTimezoneAdjustment;
    if (!!support) {
      // 支持时区调整，使用本地时间格式化
      result = formatLocalTime(time, format);
    } else {
      // 不支持时区调整，使用UTC时间格式化
      result = formatUTCTime(time, format);

      // 返回格式化后的时间字符串，根据是否指定了extra进行拼接
      return extra ? `${result} ${extra}` : result;
    }
  } else {
    // 不在App中，直接使用本地时间格式化
    result = formatLocalTime(time, format);
  }

  return result;
}

export function timestamp() {
  return moment().valueOf();
}

/**
 *
 * 前补0
 *
 * @param {number|string} num 需要补0的数字
 * @param {number} n 总位数，不够在前面补0
 * @return {string} 补0后的字符串
 */
export const prefixZero = (num, n) => (Array(n).join(0) + num).slice(-n);

/**
 *
 * 时间戳转单位时间
 *
 * @param {number|string} ms 时间戳
 * @return {string} 日、时、分、秒、毫秒、特殊小时（最大值99）
 */
export const msToRemainTime = ms => {
  if (!(typeof ms === 'number' && !Number.isNaN(ms))) {
    return {
      day: '00',
      hour: '00',
      min: '00',
      sec: '00',
      million: '000',
      extraHour: '00'
    };
  }
  const internalMS = Math.max(0, ms);
  const msPerSecond = 1000;
  const msPerMinute = msPerSecond * 60;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;

  const day = prefixZero(Math.floor(internalMS / msPerDay), 2);
  const hour = prefixZero(Math.floor((internalMS % msPerDay) / msPerHour), 2);
  const min = prefixZero(Math.floor(((internalMS % msPerDay) % msPerHour) / msPerMinute), 2);
  const sec = prefixZero(Math.floor((((internalMS % msPerDay) % msPerHour) % msPerMinute) / msPerSecond), 2);
  const million = prefixZero(internalMS % msPerSecond, 3);

  const extraHour = prefixZero(Math.min((day * 24) + parseInt(hour, 10), 99), 2);
  const doubleDigitDay = prefixZero(day, 2);

  return {
    day,
    hour,
    min,
    sec,
    million,
    extraHour,
    doubleDigitDay
  };
};

/**
 *
 * 时间戳转单位时间
 *
 * @param {number|string} ms 时间戳
 * @return {string} 日、时、分、秒、毫秒、特殊小时 天数可以2位以上
 */
export const msToRemainTimeEX = ms => {
  if (!(typeof ms === 'number' && !Number.isNaN(ms))) {
    return {
      day: '00',
      hour: '00',
      min: '00',
      sec: '00',
      million: '000',
      extraHour: '00'
    };
  }
  const internalMS = Math.max(0, ms);
  const msPerSecond = 1000;
  const msPerMinute = msPerSecond * 60;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;

  const d = Math.floor(internalMS / msPerDay);
  const day = prefixZero(d, Math.max(String(d).length, 2));
  const hour = prefixZero(Math.floor((internalMS % msPerDay) / msPerHour), 2);
  const min = prefixZero(Math.floor(((internalMS % msPerDay) % msPerHour) / msPerMinute), 2);
  const sec = prefixZero(Math.floor((((internalMS % msPerDay) % msPerHour) % msPerMinute) / msPerSecond), 2);
  const million = prefixZero(internalMS % msPerSecond, 3);

  const extraHour = prefixZero(Math.min((day * 24) + parseInt(hour, 10), 99), 2);
  const doubleDigitDay = prefixZero(day, Math.max(String(day).length, 2));

  return {
    day,
    hour,
    min,
    sec,
    million,
    extraHour,
    doubleDigitDay
  };
};

/**
 * 05.24 16:37 => 05.24 17:00
 * 05.24 17:00 => 05.24 18:00
 * 05.24 23:45 => 05.24 24:00
 *
 * @param {*} timestamp 时间戳
 * @return {string} 如 05.24 24:00
 */
// eslint-disable-next-line
export const getNextClockTime = (timestamp) => {
  const utcTime = moment.utc(timestamp);
  const Md = utcTime.format('YYYY-MM-DD');
  const currentHour = utcTime.hour();
  let nextClock = Number(currentHour) < 24 ? currentHour + 1 : currentHour;
  nextClock = `${nextClock}`.length < 2 ? `0${nextClock}` : nextClock;
  return `${Md} ${nextClock}:00`;
};
/**
 * 判断time 在今天24点前发生，且在x 天以内
 *
 * @param {number | string | Date} time  时间
 * @param {number} xDays 多少天内
 * @return {boolean}
 */
export function happenInDays(time, xDays) {
  const onlineObj = moment(Number(time));
  const nowObj = moment();
  const endOfToday = moment(nowObj.format('YYYY-MM-DD 23:59:59'));
  const endOfXDayObj = moment(
    onlineObj.add(xDays, 'days').format('YYYY-MM-DD 23:59:59')
  );
  return onlineObj.isBefore(endOfToday) && endOfXDayObj.isAfter(nowObj);
}

/**
 * 格式化时间为本地时间字符串
 * @param {ConfigType} date 要格式化的时间
 * @param format 格式化字符串, 默认是`YYYY-MM-DD HH:mm:ss`
 * @returns `YYYY-MM-DD HH:mm:ss` 格式的时间字符串
 */
export function date2locale(date, format = 'YYYY-MM-DD HH:mm:ss') {
  return moment(date).format(format);
}

/**
 * 根据日期获取偏移时区的时间戳（毫秒）
 * @param {ConfigType} date 需要获取时间戳的时间字符串
 * @param {number} offset 偏移时区,默认是`0`小时
 * @param {'h'|'m'|'s'|''} end 要设置为0的时间单位，默认到毫秒（毫秒全是 0），如果设置为 `h` 则从小时往后全部设置为 0
 * @returns 对应 `offset` 的时间戳（毫秒）
 */
export function date2offsetTs(date, offset = 0, end = '') {
  let offsetDate = moment(date).utcOffset(offset, true).millisecond(0);
  const endList = ['h', 'm', 's'];
  const findIndex = endList.findIndex(item => item === end);

  if (findIndex > -1) {
    endList.slice(findIndex).forEach(item => {
      offsetDate.set(item, 0);
    });
  }

  return offsetDate.valueOf();
}
