/**
 * @file 资产相关
 */
import { useCommonStore } from '@/store/common';
import { isClient } from '@vueuse/shared';
import { useOrderStore } from '@/store/order';
import { plus, toFormat, toFixed, gt, lt } from '@/utils/number';
import { assetApi, $futures, subApi, fiatApi } from '@/config/api';
import {
  currencyValue as getCurrencyValue
} from '@/utils/tools';
import { pinia } from './index';
import { i18n } from '@/lang/i18n';
import { tokenShowPrecision, formatNumberShow, addThousandSeparators } from '@/utils/utils';

const { t } = i18n.global;

const commonStore = useCommonStore(pinia);
const orderStore = useOrderStore(pinia);

// 资产隐藏字符
export const hiddenFlag = '********';

// 获取法币值value
const getLegalValue = (amount: string, unit: string, ratesMap: Record<string, any>) => {
  // 汇率
  const rates = { [unit]: ratesMap[unit] };

  const convertResult = getCurrencyValue(
    rates,
    amount,
    unit || null
  );

  return convertResult[1].split(',').join('');
};

/**
 * 设置总资产
 *
 * @param value 资产值
 * @param unit 单位（btc、usdt）
 * @param rateMap 汇率
 * @param isHidden 是否隐藏
 * @param legalPrecision 法币展示精度
 * @returns
 */
const setTotalAsset = (value: string, unit: string, rateMap: any, isHidden: boolean, legalPrecision: number) => {
  if (!value) {
    return { total: '-', totalText: '-', legalUnit: '-', legal: '-' };
  }

  // 换算法币
  const convertResult = getCurrencyValue(
    { [unit]: rateMap[unit] },
    value,
    unit || null
  );

  return {
    total: value,
    totalText: isHidden ? hiddenFlag : addThousandSeparators(value),
    legal: isHidden ? hiddenFlag : convertResult[1],
    legalUnit: convertResult[0]
  };
};

export const useAssetsStore = defineStore('assetsStore', {
  state: () => ({
    // 折算的目标币种
    convertCurrencyList: ['BTC', 'USDT'],
    // 折算目标币种精度
    convertCurrencyPrecision: <Record<string, number>>{
      BTC: 8,
      USDT: 2
    },
    // 当前折算的目标币种
    convertCurrency: sessionStorage.getItem('asset-unit') || 'USDT',
    // 总资产-接口返回原数据
    totalAssetRes: <Record<string, any>>{},
    // 隐藏资产
    hiddenAssets: isClient ? localStorage.getItem('hidden-assets') === 'true' : false,
    // 隐藏小额币种
    hiddenSmall: isClient ? sessionStorage.getItem('hidden-small') === 'true' : false,
    // 小额定义
    // smallValue: <Record<string, number>>{
    //   BTC: 0.001,
    //   USDT: 10
    // }
    assetTimes: <any>null,
    currentSubAsset: [],
    subAssetTimes: <any>null,
    // U本位合约资产列表
    futureAssetRes: <Array<any>>[],
    futureTimer: <null | number>null,
    // 子账户总资产
    subAccountTotalRes: <Record<string, any>>{},
    // 全量币种
    allAssets: <Array<any>>[],
    // 有余额的币种列表
    allAssetsWidthBalance: <Array<any>>[],
    // dataSource 交易账户
    AssetDataSource: <Array<any>>[],
    // 资产总览页面引导
    assetGuide: <any>{
      step: 1,
      done: true        
    },
    // 当前系统支持的充值币种和对应金额限制字符串
    tokenConfig: <string>'',
    // 当前系统支持的充值币种和对应金额限制数组
    supportTokenArr: <Array<string>>[],
    // 当前支持的法币通道
    fiatChannelSupports: <Array<any>>[],
    noFiatDepositChannelMsg: String
  }),
  getters: {
    // 资产总览
    assetOverview(state) {
      const { totalAsset, unit = state.convertCurrency } = state.totalAssetRes;
      const { ratesMap } = commonStore;

      return setTotalAsset(totalAsset, unit, ratesMap, state.hiddenAssets, state.convertCurrencyPrecision[unit]);
    },
    // 现货总资产
    spotAssetTotal(state) {
      const { coinAsset, unit = state.convertCurrency } = state.totalAssetRes;
      const { ratesMap } = commonStore;

      return setTotalAsset(coinAsset, unit, ratesMap, state.hiddenAssets, state.convertCurrencyPrecision[unit]);
    },
    // 合约总资产
    futureAssetTotal(state) {
      const { futuresCoinAsset, unit = state.convertCurrency } = state.totalAssetRes;
      const { ratesMap } = commonStore;
      const assets: any = state.futureAssetRes;
      const asset = assets[0]?.unitAssetInfo;
      const total = assets.length ? asset.futureAssetTotal : '0';

      return setTotalAsset(futuresCoinAsset, unit, ratesMap, state.hiddenAssets, state.convertCurrencyPrecision[unit]);
    },
    // 子账户总资产
    subAssetTotal(state) {
      const { totalUsdtAsset } = state.subAccountTotalRes;
      // 单位暂时不让切换
      const unit = 'USDT';
      const { ratesMap } = commonStore;

      return setTotalAsset(totalUsdtAsset, unit, ratesMap, state.hiddenAssets, state.convertCurrencyPrecision[unit]);
    },
    // subAssetTotal(state) {
    //   const { subAccountBalances, unit = state.convertCurrency } = state.totalAssetRes;
    //   const { ratesMap } = commonStore;
    //   const subBalances: any = {};
    //   let total = '0';

    //   if (!subAccountBalances || !subAccountBalances.length) {
    //     return { total: '-', totalText: '-', legalUnit: '-', legal: '-' };
    //   }

    //   subAccountBalances.forEach((item: any) => {
    //     if (unit === 'BTC') {
    //       total = plus(total, item.btcValue);

    //       const obj = {
    //         assets: item.btcValue,
    //         assetsText: state.hiddenAssets ? hiddenFlag : `${toFormat(item.btcValue, state.convertCurrencyPrecision[unit])} BTC`
    //       };

    //       subBalances[item.accountId] = obj;
    //     } else {
    //       total = plus(total, item.usdtValue);

    //       const obj = {
    //         assets: item.usdtValue,
    //         assetsText: state.hiddenAssets ? hiddenFlag : `${toFormat(item.usdtValue, state.convertCurrencyPrecision[unit])} USDT`
    //       };

    //       subBalances[item.accountId] = obj;
    //     }
    //   });

    //   const convertResult = getCurrencyValue(
    //     { [unit]: ratesMap[unit] },
    //     total,
    //     unit || null
    //   );

    //   return {
    //     total: total,
    //     totalText: state.hiddenAssets ? hiddenFlag : toFormat(total, state.convertCurrencyPrecision[unit]),
    //     legal: state.hiddenAssets ? hiddenFlag : convertResult[1],
    //     legalUnit: convertResult[0],
    //     subAssets: subBalances
    //   };
    // },

    // 现货资产
    spotAssets(state: any) {
      const { hiddenAssets } = state;
      const tokens = commonStore.currencys;
      const assetList = orderStore.assetList;
      const { ratesMap } = commonStore;

      // 币种-资产
      const assetsMap: Record<string, object> = {};
      assetList.forEach((item: any) => {
        assetsMap[item.tokenId] = item;
      });

      const assets = tokens.map((token: Record<string, any>) => {
        const asset: Record<string, any> = assetsMap[token.tokenId] || {
          free: '0',
          locked: '0',
          btcValue: '0',
          usdtValue: '0'
        };

        const all = plus(asset.free, asset.locked);

        const assetItem: any = {
          ...token,
          asset: {
            // 可用
            available: asset.free,
            showAvailable: asset.free,
            showAvailableText: toFormat(asset.free, 8),
            // 可用加千分符和精度
            availableText: hiddenAssets ? hiddenFlag : toFormat(asset.free, 8),
            // 冻结
            frozen: asset.locked,
            // 冻结加千分符和精度
            frozenText: hiddenAssets ? hiddenFlag : toFormat(asset.locked, 8),
            // 全部
            all,
            // 全部加千分符和精度
            allText: hiddenAssets ? hiddenFlag : toFormat(all, 8),
            // btc价值
            btcValue: asset.btcValue,
            btcValueText: hiddenAssets ? hiddenFlag : toFormat(asset.btcValue, 8),
            // usdt价值
            usdtValue: asset.usdtValue,
            usdtValueText: hiddenAssets ? hiddenFlag : toFormat(asset.usdtValue, 8),
            legalText: ''
          },
          legal: {
            available: '-',
            frozen: '-',
            all: ''
          }
        };

        // 法币折算 - 用usdtValue计算，也可以用btcValue计算
        const convertCurrency = 'USDT';
        const valueKey = `${convertCurrency.toLowerCase()}Value`;

        if (asset[valueKey] && ratesMap[convertCurrency]) {
          // 可用法币折算
          assetItem.legal.available = getLegalValue(assetItem.asset.available, token.tokenId, ratesMap);
          // 冻结法币折算
          assetItem.legal.frozen = getLegalValue(assetItem.asset.frozen, token.tokenId, ratesMap);
          // 总额法币折算
          assetItem.legal.all = getLegalValue(assetItem.asset.all, token.tokenId, ratesMap);

          // 估值
          const convertResult = getCurrencyValue(
            { [convertCurrency]: ratesMap[convertCurrency] },
            asset[valueKey],
            convertCurrency || null
          );

          assetItem.asset.legalText = hiddenAssets ? hiddenFlag : `${convertResult[0]}${convertResult[1]}`;
        }

        return assetItem;
      });

      // 估值 从大到小配置
      return assets.sort((a: Record<string, any>, b: Record<string, any>) => {
        return gt(toFixed(a.asset.usdtValue, 8), toFixed(b.asset.usdtValue, 8)) ? -1 : 1;
      });
    },
    // 合约资产
    futureAssets(state: any) {
      // 现货所有币种
      const tokens = commonStore.currencys;
      // 合约资产
      const { futureAssetRes, futureAssetMap } = state;
      const defAsset: any = [
        // { tokenId: 'BTC' },
        { tokenId: 'USDT' }
      ];
      const assetRes = futureAssetRes && futureAssetRes.legnth ? JSON.parse(JSON.stringify(futureAssetRes)) : defAsset;

      const arr = assetRes.map((item: Record<string, any>) => {
        const [tokenInfo] = tokens.filter((it: Record<string, any>) => it.tokenId === item.tokenId);

        const asset: Record<string, any> = futureAssetMap[item.tokenId] || {
          // 币种总资产
          total: '0',
          // 可用保证金
          availableMargin: '0',
          // 仓位保证金
          positionMargin: '0',
          // 委托保证金
          orderMargin: '0',
          // 未实现盈亏，接口后续给
          totalUnRealizedPnl: '0',
          // 保证金余额
          // marginBalance: '0'
          // 全仓未实现盈亏
          crossUnRealizedPnl: '0',
          // 逐仓未实现盈亏
          isolatedUnrealizedPnl: '0',
          // 全仓维持保证金之和
          crossMaintainMargin: '0',
          // 逐仓维持保证金之和
          isolatedMaintainMargin: '0',
          riskRate: '0',
          unit: 'USDT'
        };
        // 保证金余额 = 钱包余额 + 未实现盈亏
        const availableMargin = plus(asset.availableMargin, asset.crossUnRealizedPnl || '0');
        const showPrecision = tokenInfo?.showPrecision || 8;
        const assetItem = {
          ...tokenInfo,
          asset: {
            total: asset.total,
            totalText: state.hiddenAssets ? hiddenFlag : formatNumberShow(asset.total, tokenInfo?.tokenId),
            // 钱包余额
            balance: asset.availableMargin,
            balanceText: state.hiddenAssets ? hiddenFlag : formatNumberShow(asset.availableMargin, tokenInfo?.tokenId),
            // 保证金余额
            availableMargin: asset.availableMargin,
            availableMarginText: state.hiddenAssets ? hiddenFlag : formatNumberShow(asset.availableMargin, tokenInfo?.tokenId),
            // 未实现盈亏
            unrealisedPnl: asset.totalUnRealizedPnl,
            unrealisedPnlText: state.hiddenAssets ? hiddenFlag : formatNumberShow(asset.totalUnRealizedPnl, tokenInfo?.tokenId),
            // 可用下单余额
            availableBalance: availableMargin,
            availableBalanceText: state.hiddenAssets ? hiddenFlag : formatNumberShow(availableMargin, tokenInfo?.tokenId),
            // 仓位保证金
            positionMargin: asset.positionMargin,
            positionMarginText: formatNumberShow(asset.positionMargin, tokenInfo?.tokenId),
            // 委托保证金
            orderMargin: asset.orderMargin,
            orderMarginText: state.hiddenAssets ? hiddenFlag : formatNumberShow(asset.orderMargin, tokenInfo?.tokenId),
            // 全仓未实现盈亏
            crossUnrealizedPnl: asset.crossUnRealizedPnl || '0',
            crossUnrealizedPnlText: state.hiddenAssets ? hiddenFlag : formatNumberShow(asset.crossUnRealizedPnl, tokenInfo?.tokenId),
            isolatedUnrealizedPnl: asset.isolatedUnrealizedPnl || '0',
            isolatedUnrealizedPnlText: state.hiddenAssets ? hiddenFlag : formatNumberShow(asset.isolatedUnrealizedPnl, tokenInfo?.tokenId),
            // 全仓维持保证金之和
            crossMaintainMargin: asset.crossMaintainMargin,
            crossMaintainMarginText: state.hiddenAssets ? hiddenFlag : formatNumberShow(asset.crossMaintainMargin, tokenInfo?.tokenId),
            // 逐仓维持保证金之和
            isolatedMaintainMargin: asset.isolatedMaintainMargin,
            isolatedMaintainMarginText: state.hiddenAssets ? hiddenFlag : formatNumberShow(asset.isolatedMaintainMargin, tokenInfo?.tokenId),
            riskRate: asset.riskRate,
            unit: asset.unit,
            totalUnRealizedPnl: asset.totalUnRealizedPnl,
            totalUnRealizedPnlText: state.hiddenAssets ? hiddenFlag : formatNumberShow(asset.totalUnRealizedPnl, tokenInfo?.tokenId)
          }
        };

        return assetItem;
      });

      return arr;
    },
    subAssets(state: any) {
      const { hiddenAssets } = state;
      const tokens = commonStore.currencys;
      const assetList = state.currentSubAsset;
      const { ratesMap } = commonStore;

      // 币种-资产
      const assetsMap: Record<string, object> = {};
      assetList.forEach((item: any) => {
        assetsMap[item.tokenId] = item;
      });

      const assets = tokens.map((token: Record<string, any>) => {
        const asset: Record<string, any> = assetsMap[token.tokenId] || {
          free: '0',
          locked: '0',
          btcValue: '0',
          usdtValue: '0'
        };

        const all = plus(asset.free, asset.locked);

        const assetItem = {
          ...token,
          asset: {
            // 可用
            available: asset.free,
            // 可用加千分符和精度
            availableText: hiddenAssets ? hiddenFlag : toFormat(asset.free, 8),
            // 冻结
            frozen: asset.locked,
            // 冻结加千分符和精度
            frozenText: hiddenAssets ? hiddenFlag : toFormat(asset.locked, 8),
            // 全部
            all,
            // 全部加千分符和精度
            allText: hiddenAssets ? hiddenFlag : toFormat(all, 8),
            // btc价值
            btcValue: asset.btcValue,
            btcValueText: hiddenAssets ? hiddenFlag : toFormat(asset.btcValue, 8),
            // usdt价值
            usdtValue: asset.usdtValue,
            usdtValueText: hiddenAssets ? hiddenFlag : toFormat(asset.usdtValue, 8),
            legalText: ''
          },
          legal: {
            available: '-',
            frozen: '-',
            all: ''
          }
        };

        // 法币折算 - 用usdtValue计算，也可以用btcValue计算
        const convertCurrency = 'USDT';
        const valueKey = `${convertCurrency.toLowerCase()}Value`;

        if (asset[valueKey] && ratesMap[convertCurrency]) {
          // 可用法币折算
          assetItem.legal.available = getLegalValue(assetItem.asset.available, token.tokenId, ratesMap);
          // 冻结法币折算
          assetItem.legal.frozen = getLegalValue(assetItem.asset.frozen, token.tokenId, ratesMap);
          // 总额法币折算
          assetItem.legal.all = getLegalValue(assetItem.asset.all, token.tokenId, ratesMap);

          // 估值
          const convertResult = getCurrencyValue(
            { [convertCurrency]: ratesMap[convertCurrency] },
            asset[valueKey],
            convertCurrency || null
          );

          assetItem.asset.legalText = hiddenAssets ? hiddenFlag : `${convertResult[0]}${convertResult[1]}`;
        }

        return assetItem;
      });

      // 估值 从大到小配置
      return assets.sort((a: Record<string, any>, b: Record<string, any>) => {
        return gt(toFixed(a.asset.usdtValue, 8), toFixed(b.asset.usdtValue, 8)) ? -1 : 1;
      });
    },
    /**
     * 合约资产map
     *
     * @param state
     * @returns
     */
    futureAssetMap(state) {
      const assetMap: any = {};
      state.futureAssetRes.forEach(item => {
        assetMap[item.tokenId] = item;
      });

      return assetMap;
    },
    // U本位合约钱包总余额
    futureTotalBalance(state) {
      const { hiddenAssets } = state;

      if (this.futureAssetRes.length) {
        const asset: any = this.futureAssetRes[0].unitAssetInfo;
        const { ratesMap } = commonStore;
        const unit = asset.unit || state.convertCurrency;
        const { total, totalUnRealizedPnl, copyTradingTotalAmount = '0' } = asset;
        // 数量精度
        // const precision = state.convertCurrencyPrecision[unit];
        const precision = tokenShowPrecision(asset?.tokenId);

        // 换算法币
        const balanceRes = getCurrencyValue(
          { [unit]: ratesMap[unit] },
          total,
          unit || null
        );

        const unrealisedPnlRes = getCurrencyValue(
          { [unit]: ratesMap[unit] },
          totalUnRealizedPnl,
          unit || null
        );

        const copyTradingLegal = getCurrencyValue(
          { [unit]: ratesMap[unit] },
          copyTradingTotalAmount,
          unit || null
        );

        return {
          balance: total,
          balanceText: hiddenAssets ? hiddenFlag : formatNumberShow(total, asset?.tokenId),
          balanceLegalText: hiddenAssets ? hiddenFlag : balanceRes[1],
          unrealisedPnl: totalUnRealizedPnl,
          unrealisedPnlText: hiddenAssets ? hiddenFlag : formatNumberShow(totalUnRealizedPnl, asset?.tokenId),
          unrealisedPnlLegalText: hiddenAssets ? hiddenFlag : unrealisedPnlRes[1],
          unrealisedPnlClass: lt(totalUnRealizedPnl, 0) ? 'down' : 'up',
          copyTradingText: hiddenAssets ? hiddenFlag : formatNumberShow(copyTradingTotalAmount, asset?.tokenId),
          copyTradingLegalText: hiddenAssets ? hiddenFlag : copyTradingLegal[1],
          unit,
          legalUnit: balanceRes[0],
          totalAssetValuationText: hiddenAssets ? hiddenFlag : toFormat(plus(total, totalUnRealizedPnl), 2)
        };
      }

      const precision = tokenShowPrecision(state.convertCurrency) || 6;

      return {
        balance: '0',
        balanceText: state.hiddenAssets ? hiddenFlag :  toFormat('0.00', precision),
        balanceLegalText: '0.00',
        unrealisedPnl: '0',
        unrealisedPnlText: state.hiddenAssets ? hiddenFlag : toFormat('0.00', precision),
        unrealisedPnlLegalText: '0.00',
        unrealisedPnlClass: '',
        unit: state.convertCurrency,
        legalUnit: commonStore.prefix,
        copyTradingText: '0.00',
        copyTradingLegalText: '0.00',
        totalAssetValuation: state.hiddenAssets ? hiddenFlag : toFormat('0.00', precision),
        totalAssetValuationText: state.hiddenAssets ? hiddenFlag : toFormat('0.00', 2)

      };
    },

    /**
     * 子账户资产map
     *
     * @param state
     * @returns
     */
    subAssetMap(state) {
      const assetMap: any = {};
      state.subAccountTotalRes?.subUserBalances?.forEach((item:any) => {
        assetMap[item.userIdStr] = item;
      });
      return assetMap;
    }
  },
  actions: {
    changeGuide(val: any) {
      if (!import.meta.env.SSR) {
        window.scrollTo(0, 0);
      }
      const doneLocal = localStorage.getItem('assetGuide') == 'true';
      this.assetGuide = { step: 1, done: doneLocal };
      this.assetGuide = { ...this.assetGuide, ...val };
      if (this.assetGuide.done) {
        localStorage.setItem('assetGuide', this.assetGuide.done);
      }
    },
    clearTotalAsset() {
      clearTimeout(this.assetTimes);
    },
    // 获取总资产
    async getTotalAsset(unit?: string) {
      const res: any = await assetApi.getTotalAsset({
        unit: unit || this.convertCurrency
      });

      if (res.success) {
        this.totalAssetRes = res.data;
      }
      clearTimeout(this.assetTimes);
      this.assetTimes = setTimeout(() => {
        this.getTotalAsset();
      }, 5000);
    },
    // 获取现货币种资产明细
    getSpotAsset() {
      // 暂时用订单store里的
      orderStore.getAsset();
      orderStore.newOrderWs('asset');
    },
    // 获取汇率
    getRates() {
      commonStore.getRates();
    },
    // 设置资产折算单位
    setAssetUnit(currency: string) {
      this.convertCurrency = currency;
      sessionStorage.setItem('asset-unit', currency);
    },
    // 设置是否隐藏资产
    setHiddenAssets() {
      this.hiddenAssets = !this.hiddenAssets;
      const hide = localStorage.getItem('hidden-assets') == 'true';
      if (this.hiddenAssets != hide) {
        document.querySelector('iframe#hk-iframe')?.iFrameResizer?.sendMessage({
          hideAmount: this.hiddenAssets
        });
        isClient && localStorage.setItem('hidden-assets', String(this.hiddenAssets));
      }
    },
    // 接收react项目发送过来的状态
    updateHiddenAssets(val: boolean) {
      this.hiddenAssets = !val;
      isClient && localStorage.setItem('hidden-assets', String(!val));
    },
    // 设置隐藏小额币种
    setHiddenSmall(isHidden: boolean) {
      this.hiddenSmall = isHidden;
      sessionStorage.setItem('hidden-small', String(isHidden));
    },
    /**
     * 计算账户总资产
     *
     * @param amount 资产数量
     * @param unit 单位
     * @param rates 汇率
     * @returns
     */
    getAssetTotal(amount: string, unit: string, rates: Record<string, any>) {
      if (!amount) {
        return { total: '-', totalText: '-', legalUnit: '-', legal: '-' };
      }
      // 隐藏资产
      const { hiddenAssets, convertCurrencyPrecision } = this;

      const convertResult = getCurrencyValue(
        rates,
        amount,
        unit || null
      );

      return {
        total: amount,
        totalText: hiddenAssets ? hiddenFlag : toFormat(amount, convertCurrencyPrecision[unit]),
        legal: hiddenAssets ? hiddenFlag : convertResult[1],
        legalUnit: convertResult[0]
      };
    },

    getSubAsset(params: any) {
      if (!params) {
        return;
      }

      assetApi.getAsset(params)
        .then((res: any) => {
          if (res.success) {
            this.currentSubAsset = res.data;
          }
        });

      clearTimeout(this.subAssetTimes);
      this.subAssetTimes = setTimeout(() => {
        this.getSubAsset(params);
      }, 3000);
    },

    clearSubAsset() {
      clearTimeout(this.subAssetTimes);
    },

    // 合约资产
    async getFutureAsset() {
      const res: any = await $futures.futuresAssetAvailable({
        token_ids: 'USDT',
        unit: this.convertCurrency
      });

      if (res.success) {
        this.futureAssetRes = res.data;
      }

      this.futureTimer && clearTimeout(this.futureTimer);
      this.futureTimer = setTimeout(() => {
        this.getFutureAsset();
      }, 3000);
    },
    // 获取当前系统支持的币种以及金额
    async getTokenConfig() {
      const res = await fiatApi.getKycTokenConfig();
      if (res?.data) {
        const data: any[] = res?.data || [];
        let str = '';
        this.supportTokenArr = data;
        const hkdInfo = data.find(x => Object.keys(x)[0] == 'HKD');
        const usdInfo = data.find(x => Object.keys(x)[0] == 'USD');
        if (hkdInfo && usdInfo) {
          str = t('港元或等值美元', { hkd: Object.values(hkdInfo)[0], usd: Object.values(usdInfo)[0] });
          // str = t('hkd-t') + Object.values(hkdInfo)[0] + t('港元或等值美元(建议为') + Object.values(usdInfo)[0] + t('usd-t');
          // str = Object.values(hkdInfo)[0] + " HKD" + t('fiat-或') + t("等值美元（建议") + Object.values(usdInfo)[0] + " USD" + t("以上）");
        } else if (usdInfo) {
          str = t('等值美元（建议') + Object.values(usdInfo)[0] + ' USD' + t('以上）');
        } else {
          str = Object.values(hkdInfo)[0] + ' HKD';
        }
        
        this.tokenConfig = str;
      }  
    },
    // 获取子账户资产
    async getSubAccountTotal() {
      const res: any = await subApi.getSubUserInfo({});
      if (res.success) {
        this.subAccountTotalRes = res.data;
      }
    },
    // 获取全量币种
    async getAllAssets() {
      await commonStore.getCurrency();
      return commonStore.currencys;
    },
    // 获取有余额的币种列表
    // HKEX-6482 新增可选参数userId, 可在未切换主账号/子账号时 跨账号查询余额
    async getHasAmountList(account_type: any, userId?: any) {
      const res: any = await assetApi.getAllAssetsWithBalance({ account_type, userId });
      if (res.success) {
        this.allAssetsWidthBalance = res.data;
        return res.data;
      }
    },
    // 获取账户列表数据
    async getTableList(accountType:any) {
      const list1: any = await this.getHasAmountList(accountType);
      const list2: any = await this.getListByType(accountType);
      let dataSource = [];
      dataSource = list2.map((x: any) => {
        const detail = list1?.find((z: any) => z.tokenId == x.tokenId);
        const { free, total, usdtValue, locked, allowDeposit, allowWithdraw, allowTransfer } = detail || {};
        return {
          tokenId: x.tokenId,
          tokenName: x.tokenName,
          tokenType: x.tokenType,
          total: addThousandSeparators(total || 0.00),
          oriFree: free || 0,
          free: addThousandSeparators(free || 0.00),
          locked: addThousandSeparators(locked || 0.00),
          usdtValue: addThousandSeparators(usdtValue || 0.00),
          frozen: addThousandSeparators(locked || 0.00),
          iconUrl: x?.iconUrl,
          final: x?.final || {},
          network: x?.chainTypes?.map((networkInfo: any) => networkInfo?.network)
        };
      });
      this.AssetDataSource = dataSource;
    },
    // 根据账户类型返回数据
    async getListByType(accountType:any) {
      const data = await this.getAllAssets();
      if (accountType == '1') {
        return [...data];
      } else if (accountType == '5') {
        return (data || []).filter((x: any) => x.tokenType !== 'REAL_MONEY');
      } else if (accountType == '6' || accountType == '9') {
        return (data || []).filter((x: any) => x.tokenType == 'REAL_MONEY');
      } else if (accountType == '7' || accountType == '8') { // OPT账户&处置账户
        return [...data];
      }
    },
    // 获取法币channel list，主要用于充值跳转逻辑处理,默认只获取open的
    async getFiatChannelList() {
      // const res: any = await fiatApi.getChannelList();
      
      // if (res.code == '0') {
      //   this.fiatChannelSupports = res?.data || [];
      // } else {
      //   this.fiatChannelSupports = [];
      //   this.noFiatDepositChannelMsg = res?.message || res?.msg;
      // }

      return [];
    }

  }
});
