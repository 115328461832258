import { backOff } from 'exponential-backoff';

/**
 * 指数延迟重试函数, 重试次数为3次, 延迟时间为1s, 例如：2、6、14
 * @param fn - 重试函数
 * @param maxRetries - 最大重试次数
 * @param delay - 延迟指数时间
 */
export const backoff = <T>(fn: () => Promise<any>, maxRetries = 4, delay = 2000) => {
  let count = 0;
  const options = {
    numOfAttempts: maxRetries,
    startingDelay: delay,
    timeMultiple: 2
  };

  return backOff<T>(() => {
    count += 1;

    return new Promise(async(resolve, reject) => {
      const res = await fn();

      // 请求成功
      // 或者达到最大重试次数
      // 或者接口返回code：200006，ipqs限制
      if (res.success || count >= maxRetries || res?.code === 200006) {
        resolve(res);
      } else {
        reject(res);
      }
    });
  }, options);
};
