/**
 * @file 统一操作 API
 *
 * @example
 *
 *  - import { $api } from '@/utils/api';
 *  - $api.getDemo
 */
import axios from '@/utils/axios';

export const $api = {
  // 获取所有币种
  getCurrency() {
    return axios.get('@api/basic/tokens');
  },

  // 所有币对列表
  getSymbols() {
    return axios.get('@api/basic/symbols');
  },

  // 币，币对，币选项
  getConfig(params: {type?: string, checksum?: string}) {
    // return axios.get('https://bapi.HashKey.com/api/basic/config_v2?type=all');
    return axios.get('@api/basic/config_v2', { params: { type: 'all', ...params } });
  },
  // 查询现货交易对列表-原app_config:symbol
  spotSymbolList(params: object) {
    return axios.get('@bapi/v3/symbol/spotSymbolList', { params });
  },
  // 查询现货交易对详情
  spotSymbolDetail(params: object) {
    return axios.get('@bapi/v3/symbol/spotSymbolDetail', { params });
  },

  // 首页信息
  getIndex_config(lang?: any) {
    return axios.get('@api/basic/index_config?', { headers: { 'Accept-Language': lang } });
  },
  // 币种详情
  token_info(params: object) {
    return axios.get('@bapi/v1/basic/token', { params });
  },
  // 获取热门交易对
  getHotSymbol(params: { number: number }) {
    return axios.get<any, ApiResponse<{ hotFutures: { id: number, symbolId: string, position: number }[], hotSpot: { id: number, symbolId: string, position: number }[], [property: string]: any }>>('@bapi/v1/basic/hot_new_symbols', { params });
  },
  // 查询通用配置
  getBaseConfigByKeys(data: object) {
    return axios.post('@api/exchange/api/cfs/baseConfig/getByKeys',  data, { headers: { 'Content-Type': 'application/json' } });
  }
};

// 登录注册找回密码个人中心
export const ucApi = {
  deviceInfo(params: any) {
    return axios.get('@api/v3/device/info', { params });
  },
  // 用户授权获取授权码
  authorize(data: object) {
    return axios.post('@auth/user/authorize', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // auth-server授权注册后创建用户
  createUser(data: object) {
    return axios.post('@bapi/v3/auth/oauth/createUser', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  /**
   * 注销-查询用户注销记录是否符合注销
   */
  getTerminateInfo() {
    return axios.get('@bapi/v1/user/terminate/getTerminateInfo');
  },
  /**
   * 注销-查询资产和HSK积分
   */
  getTerminateAssetsInfo() {
    return axios.get('@bapi/v1/user/terminate/getTerminateAssetsInfo');
  },
  /**
   * 注销-提交注销
   * @param terminateType: 注销原因（1:解绑 2:更换 3:不想使用 4:其他）
   * @param terminateRemark: 注销原因备注（选择其他时具体原因）
   */
  terminate(data: object) {
    return axios.post('@bapi/v1/user/terminate', {}, {
      params: data
    });
  },
  // 获取当前设备信息
  getDeviceInfo() {
    return axios.get('@api/v3/device/info');
  },
  getVpnInfo(data: object) {
    return axios.post('@bapi/v1/user/check_device', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // vpn提示
  vpnBlocked(params: any) {
    return axios.get('@bapi/v1/user/vpn_blocked', { params });
  },
  // vpn解封
  vpnDeBlock(data: object) {
    return axios.post('@bapi/v1/user/vpn_deblocking', data);
  },
  // 检测国家地区
  checkArea(params: any) {
    return axios.get('@bapi/v1/user/checkArea', { params });
  },
  // 邮箱注册
  emailRegister(data: object) {
    return axios.post('@api/user/email_sign_up', data);
  },
  // 手机注册
  mobileRegister(data: object) {
    return axios.post('@api/user/mobile_sign_up', data);
  },
  // passport 注册
  userRegister(data: object) {
    return axios.post('@group/user/register', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 登出
  logout(data: object) {
    return axios.post('@api/user/authorize_cancel', data);
  },
  // passport登出
  passportLogout(params: any) {
    return axios.get('@group/logout/goodbye', { params });
  },
  // 未登录状态 邮箱验证码
  sendEmailVerifyCode(data: object) {
    return axios.post('@api/common/send_email_verify_code', data);
  },
  // passport 邮箱验证码
  passportSendEmailVerifyCode(data: object) {
    return axios.post('@group/message/bapi/send_email_verify_code', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 未登录状态 手机验证码
  sendSmsVerifyCode(data: object) {
    return axios.post('@api/common/send_sms_verify_code', data);
  },
  // passport 手机验证码
  passportSendSmsVerifyCode(data: object) {
    return axios.post('@group/message/bapi/send_sms_verify_code', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 未登录状态验证码 不需要极验
  sendCommonVerifyCode(data: object) {
    return axios.post('@api/common/send_verify_code', data);
  },
  // 国家列表
  getCountries(params: object) {
    return axios.get('@bapi/v1/basic/business/countries', { params });
  },
  // 注册页面国家区号前缀
  getRegCountries(params: object) {
    return axios.get('@bapi/v1/basic/business/countries', { params });
  },
  userinfo() {
    return axios.get('@api/user/get_base_info');
  },
  // 登录态
  isLogin() {
    return axios.get('@bapi/v1/user/simple-base-info');
  },
  // 手机或邮箱登录
  login(data: object) {
    return axios.post('@api/user/authorize', data);
  },
  // 登录时，二次验证邮箱
  send_email_verify_code_authorize_advance(data: object) {
    return axios.post(
      '@api/user/send_email_verify_code/authorize_advance',
      data
    );
  },
  // 登录时，二次验证手机验证码
  send_sms_verify_code_authorize_advance(data: object) {
    return axios.post('@api/user/send_sms_verify_code/authorize_advance', data);
  },
  // 登录二次验证
  login_step2(data: object) {
    return axios.post('@api/user/authorize_advance', data);
  },
  // 手机重置密码-检查接口
  resetPasswordCheckMobile(data: object) {
    return axios.post('@api/user/mobile_find_pwd_check1', data);
  },
  // 邮箱重置密码-检查接口
  resetPasswordCheckEmail(data: object) {
    return axios.post('@api/user/email_find_pwd_check1', data);
  },
  // 重置密码-安全验证码发送
  resetPwdSendCode(data: object) {
    return axios.post('@api/user/send_verify_code/find_pwd', data);
  },
  // 重置密码-安全验证码
  resetPwdVerify2FA(data: object) {
    return axios.post('@api/user/find_pwd_check2', data);
  },
  // 重置密码
  resetPassword(data: object) {
    return axios.post('@api/user/find_pwd', data);
  },

  // 校验手机号
  registerPreCheck(data: object) {
    return axios.post('$bapi/v1/user/register/pre-check', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },

  // 不信任
  distrust(data: object) {
    return axios.post('@api/user/distrust', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },

  // 第三方登录
  // 三方账号验证+查询绑定状态
  check_auth(data: object) {
    return axios.post('@bapi/v3/oauth/check_auth', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  passport_check_auth(data: object) {
    return axios.post('@group/bapi/oauth/check_auth', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 校验极验
  check_captcha(data: object) {
    return axios.post('@bapi/v3/oauth/check_captcha', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  passport_check_captcha(data: object) {
    return axios.post('@group/bapi/oauth/check_captcha', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 校验极验+发送验证码(GA不发送)
  send_verify_code(data: object) {
    return axios.post('@bapi/v3/oauth/send_verify_code', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  passport_send_verify_code(data: object) {
    return axios.post('@group/bapi/oauth/send_verify_code', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 已绑定账户登录
  auth_login(data: object) {
    return axios.post('@bapi/v3/oauth/login', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  passport_auth_login(data: object) {
    return axios.post('@group/bapi/oauth/login', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 检查密码
  check_password(data: object) {
    return axios.post('@bapi/v3/oauth/check_password', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  passport_check_password(data: object) {
    return axios.post('@group/bapi/oauth/check_password', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 绑定+登录
  auth_bind(data: object) {
    return axios.post('@bapi/v3/oauth/bind', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  passport_auth_bind(data: object) {
    return axios.post('@group/bapi/oauth/bind', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 创建账户+绑定+登录
  create_user(data: object) {
    return axios.post('@bapi/v3/oauth/create_user', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // passport 创建账户+绑定+登录
  passport_create_user(data: object) {
    return axios.post('@group/bapi/oauth/create_user', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 安全中心-查询三方绑定信息
  account_list() {
    return axios.get('@bapi/v3/oauth/account/list');
  },
  // 安全中心-绑定三方认证
  account_bind(data: object) {
    return axios.post('@bapi/v3/oauth/account/bind', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 安全中心-解除三方认证
  account_unbind(data: object) {
    return axios.post('@bapi/v3/oauth/account/unbind', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 登录校验
  authLogin(data: object) {
    return axios.post('@api/v1/user/quick_authorize_advance', data);
  },
  // 登录校验（2fa）
  authLoginV2(data: object) {
    return axios.post('@api/v1/user/quick_authorize_advance_v2', data);
  },
  // 获取request_id
  getRequestId() {
    return axios.get('@bapi/v1/user/get-request-id');
  }
};

// 用户登录后 查询获取 验证码
export const userApi = {
  // 币余额查询
  getUserLoginList(data: object) {
    return axios.post('@api/user/authorize_log', data);
  },
  // 登录后获取邮箱验证码
  sendEmailVerifyCode(data: object) {
    return axios.post('@api/user/send_email_verify_code', data);
  },
  // 登录后获取手机验证码
  sendSmsVerifyCode(data: object) {
    return axios.post('@api/user/send_sms_verify_code', data);
  },
  // 3选2 FA 发送手机或者邮箱验证码
  sendVerifyCode(params: any) {
    return axios.get('@api/v1/user/send_verify_code', { params });
  },
  // 3选2 FA 验证码校验
  checkSecurityVerify(data: object) {
    return axios.post('@api/exchange/api/cfs/2fa/code/verify/v2', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 邮箱登录 绑定手机号
  bindMobile(data: object) {
    return axios.post('@api/user/bind_mobile', data);
  },
  // 手机登录 绑定邮箱
  bindEmail(data: object) {
    return axios.post('@api/user/bind_email', data);
  },
  // 手机登录 绑定邮箱
  bindEmailV3(data: object) {
    return axios.post('@bapi/v3/user/bind_email', data);
  },
  bindMobileV3(data: object) {
    return axios.post('@bapi/v3/user/bind_mobile', data);
  },
  // 获取GA secretKey  before_bind_ga
  beforeBindGa(data: object) {
    return axios.post('@api/user/before_bind_ga', data);
  },
  // 换绑获取GA secretKey  before_bind_ga
  beforeChangeBindGa(data: object) {
    return axios.post('@api/user/before_alter_ga', data);
  },
  // recaptcha.net
  // 114.250.64.34
  // 绑定GA
  bindGa(data: object) {
    return axios.post('@api/user/bind_ga', data);
  },

  // 获取用户KYC级别信息
  getUserKycInfo() {
    return axios.get('@api/user/kyclevel');
  },

  // 实名信息
  getVerifyInfo() {
    return axios.get('@api/user/verify_info');
  },
  // 换绑GA
  changeBindGa(data: object) {
    return axios.post('@api/user/alter_ga', data);
  },
  // 换绑email
  changeBindEmail(data: object) {
    return axios.post('@api/user/alter_email', data);
  },
  // 换绑mobile
  changeBindMobile(data: object) {
    return axios.post('@api/user/alter_mobile', data);
  },
  // 设置防钓鱼码 edit_anti_phishing_code
  fishCode(data: object) {
    return axios.post('@api/user/edit_anti_phishing_code', data);
  },
  // 设置资金密码
  fundPassword(data: object) {
    return axios.post('@api/user/trade_pwd/set', data);
  },
  // 修改登录密码
  updatePassword(data: object) {
    return axios.post('@api/user/update_pwd', data);
  },
  // 获取证件类别
  getIdCardType() {
    return axios.get('@api/basic/id_card_type');
  },
  // 解绑GA
  unbindGa(data: object) {
    return axios.post('@api/user/unbind_ga', data);
  },
  // unbind_email 解绑邮箱
  unbindEmail(data: object) {
    return axios.post('@api/user/unbind_email', data);
  },
  // unbind_mobile 解绑手机号
  unbindMobile(data: object) {
    return axios.post('@api/user/unbind_mobile', data);
  },
  // 2FA绑定信息
  check2FA(data: object = {}) {
    return axios.get('@api/exchange/api/cfs/2fa/check', data);
  },
  // 用户kyc基础认证
  kycBasicVerify(data: object) {
    return axios.post('@api/user/kyc/basicverify', data);
  },

  // 用户证件照KYC认证
  kycphotoverify(data: object) {
    return axios.post('@api/user/kyc/photoverify', data);
  },

  // 未读消息数量
  messageUnreadCount() {
    return axios.get('@bapi/v3/message/inner/getCount');
  },
  //  消息列表-type 1-系统 2-交易 3-存管 4-活动
  //  pageNum
  //  pageSize
  messageList(params: any) {
    return axios.get('@bapi/v3/message/inner/getPage', { params });
  },
  // 标识消息为已读
  //  id 消息id，不传则是清除已读
  readMessage(params: any) {
    return axios.get('@bapi/v3/message/inner/setReaded', { params });
  },

  // 获取子账户列表 account/sub_account/query
  queryAccountList(data: object) {
    return axios.post('@api/account/sub_account/query', data);
  },

  // 个人pi-查询api申请状态
  getApiApplyStatus() {
    return axios.get('@bapi/v1/user/apikey-status');
  },

  // 个人pi-获取ap申请api答题信息
  getApiApplyPaper() {
    return axios.get('@bapi/v1/user/gen-apikey-paper');
  },

  // 个人pi-提交ap申请api答题信息
  submitApiApplyPaper(data: object) {
    return axios.post('@bapi/v1/user/apikey-submit', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },

  // 获取api列表  api_keys
  getApiList(data: object) {
    return axios.post('@api/user/api_keys', data);
  },
  // 创建api  user/api_key/create
  createApiKey(data: object) {
    return axios.post('@api/user/api_key/create', data);
  },
  // 更新api  user/api_key/update_ips
  updateApiKeys(data: object) {
    return axios.post('@api/user/api_key/update_ips', data);
  },
  // 删除api key    api_key/delete
  delApiKeys(data: object) {
    return axios.post('@api/user/api_key/delete', data);
  },
  // 修改api key 状态 user/api_key/change_status
  changeApiKeyStatus(data: object) {
    return axios.post('@api/user/api_key/change_status', data);
  },
  // 获取邀请码 invitation/info/get
  getInviteCode(data: object) {
    return axios.post('@api/invitation/share/template_info', data);
  },
  // 获取邀请数据 invitation/info/get
  getInviteData(data: object) {
    return axios.post('@api/invitation/info/get', data);
  },
  // 获取邀请列表 invitation/relation/list?
  getInviteList(data: object) {
    return axios.post('@api/invitation/relation/list', data);
  },
  // 返佣记录明细列表 invitation/bonus/list
  getReturnBonusList(data: object) {
    return axios.post('@api/invitation/bonus/list', data);
  },
  // 检查Hello hashkey是否存在kyc数据
  checkUser() {
    return axios.get('@bapi/v3/hellohaskkey/check/user');
  },
  // 是否同意同步用户数据
  syncData(data: object) {
    return axios.post('@bapi/v3/hellohaskkey/sync', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 删除设备
  deleteDevice(data: object) {
    return axios.post('@bapi/v3/trustdevice/manage/delete/device/V2', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 设备列表
  deviceList() {
    return axios.get('@bapi/v3/trustdevice/manage/list/V2');
  },
  // 提交ipqs信息
  submitIPQS(data: object) {
    return axios.post('@bapi/v1/user/gen_fingerprint', data, {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    });
  },
  // 提交ipqs耗时
  submitRisk(data: object) {
    return axios.post('@risk/ipqsCostTime', data, {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    });
  },
  // 同盾指纹
  getTDInfo(data: object) {
    return axios.post('@risk/tdDeviceInfo', data, {
      headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    });
  },
  // 设置语言
  setLanguage(data: object) {
    return axios.post('@bapi/v3/user/common/language', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 邀请好友申请成为渠道用户
  sendChannelMessage(data: object) {
    return axios.post('@bapi/v3/invite/common/submit/channelMessage', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 邀请好友获取用户信息
  getInviteUserInfo() {
    return axios.get('@bapi/v3/invite/user/info');
  },
  // 获取偏好设置营销通知状态
  getPreferences() {
    return axios.get('@bapi/v3/user/marketing_flag');
  },
  // 设置偏好设置营销通知状态
  setPreferences(data: object) {
    return axios.post('@bapi/v3/user/marketing_flag', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 邮件模版点进来，取消订阅
  cancelMarketingSubscribe(data:object) {
    return axios.post('@bapi/v3/user/cancel_marketing_subscribe', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 用户开通创新区/观察区
  openRegion(data: object) {
    return axios.post('@bapi/v3/user/cash/open/region', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 查询用户开通信息
  regionStatus(params: any) {
    return axios.get('@bapi/v3/user/cash/query/region/status', { params });
  },
  // 修改密码-提交密码
  updatePwdNew(data: object) {
    return axios.post('@api/user/updatePwdNew', data);
  },
  updatePwdNew2(data: object) {
    return axios.post('@bapi/v3/user/check/updatePwd', data,
      {
        headers: { 'Content-Type': 'application/json' }
      }
    );
  },
  // 修改密码-提交修改
  confirmUpdatePwdNew(data: object) {
    return axios.post('@api/user/confirmUpdatePwdNew', data);
  }
};

export const assetApi = {
  // 币余额查询
  getAsset(params: any = '') {
    return axios.get('@api/asset/get', { params });
  },

  // 总资产
  getTotalAsset(params: object) {
    return axios.get('@api/v1/asset/info', { params });
  },

  // 获取汇率
  getRates(data: object) {
    return axios.post('@api/quote/v1/rates', data);
  },

  // 下单
  createOrder(data: object) {
    return axios.post('@api/v1.1/order/create', data);
  },

  // 撤单
  cancelOrder(data: object) {
    return axios.post('@api/order/cancel', data);
  },

  // 全部撤单
  cancelAllOrder(data: object = {}) {
    return axios.post('@api/order/batch_cancel', data);
  },

  // 计划委托下单
  createPlanOrder(data: object) {
    return axios.post('@api/order/plan_spot/create', data);
  },

  // 取消计划委托
  cancelPlanOrder(data: object) {
    return axios.post('@api/order/plan_spot/cancel', data);
  },

  // 批量取消计划委托
  cancelAllPlanOrder(data: object) {
    return axios.post('@api/order/plan_spot/batch_cancel', data);
  },

  // 当前委托
  openOrders(params: object) {
    const param = JSON.parse(JSON.stringify(params));
    delete param.source;
    return axios.post('@api/order/open_orders', param);
  },

  // 历史委托，拉取历史数据
  historyOrders(params: object) {
    const param = JSON.parse(JSON.stringify(params));
    delete param.source;
    return axios.post('@api/order/trade_orders', param);
  },

  // 历史委托，拉取历史数据
  historyRrades(params: object) {
    const param = JSON.parse(JSON.stringify(params));
    delete param.source;
    return axios.post('@api/order/my_trades', param);
  },

  // 成交明细
  matchInfo(params: object) {
    return axios.post('@api/order/match_info', params);
  },
  // get_ws_token: `${prefix}-/user/get_ws_token`, // 获取websocket握手token ,// 订单ws连接前，握手接口
  // 查询导出明细
  tradeExportList(params: { business_type: string, user_id: number}) {
    return axios.get<any, ApiResponse<InterfaceExportListResponse>>('@bapi/v3/trade/export/list', { params });
  },

  // 导出明细
  tradeExport(params: { apply_time: number; business_type: string; end_time: number; start_time: number; user_id: number }) {
    return axios.post<any, ApiResponse<unknown>>('@bapi/v3/trade/export', params, {
      headers: { 'Content-Type': 'application/json' }
    });
  },

  // 下载导出文件
  downloadFile(params: { file_name?: string, file_key?: string}) {
    return axios.get<any, ApiResponse<{data: string}>>('@bapi/v3/trade/export/download',  { params });
  },

  // 卖出配置
  sellConfig(params: object) {
    return axios.post<any, ApiResponse<InterfaceSellConfigResponse>>('@api/order/sell_buy/config', params);
  },

  // 获取当前计划委托
  openPlanOrders(params: object) {
    const param = JSON.parse(JSON.stringify(params));
    delete param.source;
    return axios.post('@api/order/plan_spot/open_orders', param);
  },

  // 获取历史计划委托
  historyPlanOrders(params: object) {
    const param = JSON.parse(JSON.stringify(params));
    delete param.source;
    return axios.post('@api/order/plan_spot/trade_orders', param);
  },

  // 获取计划委托明细
  getPlanOrderDetail(params: object) {
    return axios.post('@api/order/plan_spot/get', params);
  },

  // 资产划转
  assetTransfer(params: object) {
    return axios.post('@bapi/v1/account/transfer', params);
  },

  // 账户资产
  getAccount() {
    return axios.get('@api/account/get');
  },

  // 可划转资产余额查询
  transferAvailable(params: object) {
    return axios.get('@bapi/v1/account/sub-account/transfer-available', { params });
  },

  // 创建子账户
  createAccount(params: object) {
    return axios.post('@api/account/sub_account/create', params);
  },

  // 子账户类型
  accountType(params: object) {
    return axios.get('@api/basic/sub_account/support_account_type_v1', params);
  },

  // 账户列表
  accountList() {
    return axios.get('@api/account/sub_account/query');
  },

  // 禁止划转设置
  accountLimit(params: object) {
    return axios.get('@api/v1/account/sub_account/transfer/limit', params);
  },

  // 获取近期充提记录
  // /account/deposit-withdraw-list
  depositWithdrawList(params: object) {
    return axios.get('@api/account/deposit-withdraw-list', { params });
  },

  // 请求所有子账户
  sendSubAccount() {
    return axios.get('@bapi/v1/account/sub-accounts-info');
  },
  // 查询可划转金额
  getTransferAvailable(params: object) {
    return axios.get('@bapi/v1/account/transfer-available', { params });
  },
  // 划转限额
  getTransferLimit(params: object) {
    return axios.get('@bapi/v1/account/transfer/risk_limit', { params });
  },
  // 账户划转
  sendAccountTransfer(data: object) {
    return axios.post('@bapi/v1/account/transfer', data);
  },
  // 获取全量币种
  getAllAssets() {
    return axios.get('@api/basic/tokens');
  },
  // 获取全量有余额的币种列表
  getAllAssetsWithBalance(params:object) {
    return axios.get('@bapi/v1/assets', { params });
  },
  // 获取opt账户交易记录
  getOptTradeRecord(params:object) {
    return axios.get('@api/exchange/api/cfs/opt/trade/records', { params });
  },
  // 账户记录-充值
  getCustodyDepositRecord(params: object) {
    return axios.get('@api/exchange/api/cfs/custodyAccount/rechargeRecord', { params });
  },
  // 账户记录-充值
  getCustodyWithdrawRecord(params: object) {
    return axios.get('@api/exchange/api/cfs/custodyAccount/withdrawRecord', { params });
  },
  // 账户记录-划转
  getCustodyTransferRecord(data: object) {
    return axios.post('@bapi/v3/asset/flow/query', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 账户记录-导出-充值
  exportCustodyDepositRecord(params: object) {
    return axios.get('@api/exchange/api/cfs/rechargeRecordExport', { params, responseType: 'blob' });
  },
  // 账户记录-导出-提现
  exportCustodyWithdrawRecord(params: object) {
    return axios.get('@api/exchange/api/cfs/withdrawRecordExport', { params, responseType: 'blob' });
  },
  // 账户记录-导出-划转
  exportCustodyTransferRecord(params: object) {
    return axios.get('@api/exchange/api/cfs/transferRecordExport', { params, responseType: 'blob' });
  },
  // 账户记录-充值失败原因
  getDepositFailResult(params: object) {
    return axios.get('@api/exchange/api/cfs/custodyAccount/approval/result', { params });
  },
  // 账户记录-取消提现
  tryCancelWithdraw(data: object) {
    return axios.post('@api/exchange/api/cfs/withdraw/tryCancelWithdraw',  data, { headers: { 'Content-Type': 'application/json' } });
  },
  // 账户记录-申请退款
  refundSubmit(data: object) {
    return axios.post('@api/exchange/api/cfs/custodyAccount/refundSubmit', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 法币白名单银行卡
  getWhiteBankAccount() {
    return axios.get('@api/exchange/api/cfs/fiat2/client/bank/queryWhiteBankAccount');
  },
  // 计算提现手续费
  calcFee(params : object) {
    return axios.get('@api/exchange/api/cfs/fiat/asset/withdraw/fee', { params });
  },
  // 获取提现记录
  getWithdrawRecord(data: object) {
    return axios.post('@api/exchange/api/cfs/fiat2/order/withdraw/records', data, { headers: { 'Content-Type': 'application/json' } });
  },
  // 提现订单明细
  getOrderDetail(data: object) {
    return axios.post('@api/exchange/api/cfs/fiat2/order/withdraw/detail', data, { headers: { 'Content-Type': 'application/json' } });
  },
  // 校验 提现限额 和 余额不足
  onPreCheck(data: object) {
    return axios.post('@api/exchange/api/cfs/fiat2/withdraw/withdraw/preCheck', data, { headers: { 'Content-Type': 'application/json' } });
  },
  // 提现提交
  submitWithdraw(data: object) {
    return axios.post('@api/exchange/api/cfs/fiat2/withdraw/submit', data, { headers: { 'Content-Type': 'application/json' } });
  },
  // 编辑银行卡输入体验优化
  bankInfoPrompt(data: object) {
    return axios.post('@api/exchange/api/cfs/fiat/client/bank/account/bankInfoPrompt', data, { headers: { 'Content-Type': 'application/json' } });
  },
  // 编辑银行卡
  updateBank(data: object) {
    return axios.post('@api/exchange/api/cfs/fiat2/client/bank/update', data, { headers: { 'Content-Type': 'application/json' } });
  },
  // 获取提现金额相关信息
  getWithdrawInfo(params: object) {
    return axios.get('@api/exchange/api/cfs/fiat/order/withdraw/getWithdrawInfo', { params });
  },
  // 获取FAQ
  getFaq(params: object) {
    return axios.get('@api/exchange/api/cfs/fiat/faq/list', { params });
  },
  // 获取当前支持的币种及最小金额
  getTokenConfig() {
    return axios.get('@api/exchange/api/cfs/fiat/asset/kycTokenConfig');
  },
  // ZA-获取getUisOrder
  getUisOrderId() {
    return axios.get('@api/exchange/api/cfs/fiat/pre/deposit/getUisOrder');
  },
  // ZA-根据bankId获取preOrderId
  getPreOrderId(data: object) {
    return axios.post('@api/exchange/api/cfs/fiat/pre/deposit/getPreDepositOrderByAccountId', data, { headers: { 'Content-Type': 'application/json' } });
  },
  // ZA-STEP1-创建
  createZaAccount(data: object) {
    return axios.post('@api/exchange/api/cfs/fiat/client/bank/account/create', data, { headers: { 'Content-Type': 'application/json' } });
  },
  // ZA-STEP1-银行卡列表
  getUserBankAccount(params: object) {
    return axios.get('@api/exchange/api/cfs/fiat/pre/deposit/getUserBankAccount', { params });
  },
  // ZA-STEP1-保存
  saveBankAccount(data: object) {
    return axios.post('@api/exchange/api/cfs/fiat/pre/deposit/savePreDeposit', data, { headers: { 'Content-Type': 'application/json' } });
  },
  // ZA-STEP1-编辑银行卡
  editAccount(data: object) {
    return axios.post('@api/exchange/api/cfs/fiat/client/bank/account/update', data, { headers: { 'Content-Type': 'application/json' } });
  },
  // ZA-STEP2-Query
  getRechargeInfo(data: object) {
    return axios.post('@api/exchange/api/cfs/fiat/pre/deposit/getChargeInfo', data, { headers: { 'Content-Type': 'application/json' } });
  },
  // ZA-STEP2-获取币种最小充值金额
  getMinAmount(params: object) {
    return axios.get('@api/exchange/api/cfs/fiat/asset/info', { params });
  },
  // ZA-STEP2-获取HBL账户信息
  getHblBankInfo(params: object) {
    return axios.get('@api/exchange/api/cfs/fiat/pre/deposit/getHblBankAccountByAssetId', { params });
  },
  // ZA-STEP2-转账完成
  transferAmount(data: object) {
    return axios.post('@api/exchange/api/cfs/fiat/pre/deposit/transferAccount', data, { headers: { 'Content-Type': 'application/json' } });
  },
  // ZA-STEP2-发送邮件
  sendEmailInfo(params: object) {
    return axios.get('@api/exchange/api/cfs/fiat/pre/deposit/sendHblBankInfo', { params });
  },
  // ZA-STEP3-Query
  getVoucherInfo(params: object) {
    return axios.get('@api/exchange/api/cfs/fiat/pre/deposit/getVoucherInfo', { params });
  },
  // ZA-STEP3-保存凭证
  saveVoucher(data: object) {
    return axios.post('@api/exchange/api/cfs/fiat/pre/deposit/saveVoucher', data, { headers: { 'Content-Type': 'application/json' } });
  },
  // ZA-STEP3-提交
  submitOrder(data: object) {
    return axios.post('@api/exchange/api/cfs/fiat/order/deposit/submitOrder', data, { headers: { 'Content-Type': 'application/json' } });
  },
  // ZA-STEP3-删除凭证
  deleteVoucher(params: object) {
    return axios.get('@api/exchange/api/cfs/fiat/pre/deposit/removeVoucher', { params });
  },
  // 绑定渠道码
  bindChannelCode(data: object) {
    return axios.post('@bapi/v1/activity/za/channel/bind', data, { headers: { 'Content-Type': 'application/json' } });
  },
  // 查询活动详情
  getEventsInfo(eventName: any) {
    return axios.get(`@bapi/v3/events/${eventName}`);
  },
  // 查询ZA白卡列表
  listAccountByBankChannel(params: object) {
    return axios.get('@api/exchange/api/cfs/fiat/client/bank/account/listWhiteBankAccountByBankChannel', { params });
  },
  // 获取最新成交
  getTrades(params: object) {
    return axios.get('@api/quote/v1/trades', { params });
  },
  // 获取热门币种
  getHotAssets() {
    return axios.get<any, ApiResponse<Common.Token[]>>('@api/basic/hot_tokens');
  },
  // 地址认证 - 第三方
  onVerifyOther(data: object) {
    return axios.post('@api/exchange/api/cfs/custody/address/travelRuleCertified',  data, { headers: { 'Content-Type': 'application/json' } });
  },
  // 获取提现详情
  getWithdrawDetail(params:object) {
    return axios.get('@api/exchange/api/cfs/custodyAccount/withdrawRecord/detail', { params });
  },
  // 获取【提现中】提示文案
  getWithdrawWord() {
    return axios.get('@api/exchange/api/cfs/time/plan/withdrawV1');
  },
  // 查询所有的资产以及配置信息
  getAssetNetworkInfo() {
    return axios.get('@api/exchange/api/cfs/custody/network/list');
  }
};

// 白名单管理
export const whitelistApi = {
  // 白名单管理-列表
  getWhiteAddressList(params: object) {
    return axios.get('@api/exchange/api/cfs/custody/address/whiteAddressList', { params });
  },
  // 获取充值白名单列表 - new api[2024-1-2]
  getWhiteAddrList(params: object) {
    return axios.get('@api/exchange/api/cfs/deposit/depositWhite', { params });
  },
  /**
   * 删除白名单地址
   * @param data {depositAddressId:, withdrawAddressId:}
   */
  deleteWhitelist(paramsStr: string) {
    return axios.post(`@api/exchange/api/cfs/custody/address/remove?${paramsStr}`);
  },
  /**
   * 修改白名单昵称
   * @param data {id:'', remark:''}
   */
  updateWhitelistRemark(data:object) {
    return axios.post('@api/exchange/api/cfs/custody/address/withdraw/update', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 地址认证 -> 获取预充值随机金额
  getRandomAmount(data: object) {
    return axios.post('@api/exchange/api/cfs/custody/address/whitelist/preDeposit/randomAmount', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 币种是否支持 充值认证方式 进行地址认证
  supportAddrVerify(data:object) {
    return axios.post('@api/exchange/api/cfs/custody/address/whitelist/preDeposit/supportAddressVerification', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // BMEX-341 HKEX-6714 地址认证校验唯一性
  checkAddressVerifyUniqueness(params: { address: string, orderId?: string }) {
    return axios.get('@api/exchange/api/cfs/custody/address/check/uniqueness', {
      params
    });
  }
};

// 现货接口
export const spotApi = {
  // 添加自选
  favoriteAdd(data: object) {
    return axios.post('@api/user/favorite/create', data);
  },
  // 取消自选
  favoriteCancel(data: object) {
    return axios.post('@api/user/favorite/cancel', data);
  },
  // 获取币种列表哦
  getSpotSymbolList() {
    return axios.get('@api/contract/funding_rates', {});
  },
  // 收益率计算
  getProfitInfo(params: object) {
    return axios.get('@bapi/v1/futures/calculator/profit_info', { params });
  },
  // 预估强平价
  getLiquidationPrice(params: object) {
    return axios.get('@api/contract/calculator/liquidation_price', { params });
  }
};

export const $ex = {
  // k线http
  kline_history(par: any) {
    return axios.get('@api/quote/v1/klines', { params: par });
  },
  depth(par: any) {
    return axios.get('@api/quote/v1/depth', { params: par });
  },
  serverTime(par: any) {
    return axios.get('@api/quote/v1/time', { params: par });
  }
};
export const $assets = {
  // 校验地址是否合法
  valid(data: object) {
    return axios.post('@bapi/v1/withdraw/address/valid', data);
  },
  // 充币记录
  deposit_order_list(par: any) {
    return axios.get('@api/asset/deposit/order_list', { params: par });
  },
  // 取消提币
  cancel(data: object) {
    return axios.post('@api/asset/withdrawal/order/cancel', data);
  },
  // 提币记录
  withdrawal_order_list(par: any) {
    return axios.get('@api/asset/withdrawal/order_list', { params: par });
  },
  // 充币
  balance_deposit(par: any) {
    return axios.get('@api/asset/deposit/address', { params: par });
  },
  // 提币，获取余额，手续费
  quota_info(par: any) {
    return axios.get('@api/asset/withdrawal/quota_info', { params: par });
  },
  // 提币地址黑名单判断
  withdraw_address_check(par: any) {
    return axios.get('@api/asset/withdrawal/address/check', { params: par });
  },
  // 获取提币地址
  get_withraw_address(data: any) {
    return axios.post('@api/asset/withdrawal/address_list', data);
  },
  // 提币 step1
  withdraw(data: any) {
    return axios.post('@bapi/v1/withdraw/create', data);
  },
  // 提币 step2
  withdraw_verify(data: any) {
    return axios.post('@api/asset/withdrawal/verify', data);
  },
  // 添加提币地址
  add_withraw_address(data: any) {
    return axios.post('@api/asset/withdrawal/address/add', data);
  },
  del_withraw_address(data: any) {
    return axios.post('@api/asset/withdrawal/address/delete', data);
  },
  // 资产-其他记录（包括划转）
  getOtherRecord(params: any) {
    return axios.get('@api/asset/balance_flow', { params });
  },
  // 资产-划转记录
  getTransferRecord(params: any) {
    return axios.get('@bapi/v1/asset/account_transfer', { params });
  },
  getAssetsRecord(data: any) {
    return axios.post('@bapi/v3/asset/flow/query', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 资金明细下载
  exportAssetsRecord(data: object) {
    return axios.post('@bapi/v3/asset/flow/export', data, {
      headers: { 'Content-Type': 'application/json' },
      responseType: 'blob'
    });
  }
};
export const $contract = {
  // 获取试题
  getQuestion(data: object) {
    return axios.post('@bapi/v3/questionnaire/query', data);
  },
  // 提交开通合约账户
  openContract(data: object) {
    return axios.post('@bapi/v1/user/open-futures', data);
  },
  isOpenContract() {
    return axios.post('@bapi/v1/user/feature_is_open');
  }
};
export const $download = {
  downloadInfo() {
    return axios.get('@api/basic/download/info', {});
  }
};

export const $futures = {
  // 获取合约交易对总持仓量
  getPositionTotal(params: any) {
    return axios.get('@bapi/v1/futures/position/total', { params });
  },
  // 资金费率
  fundingRates(params: any) {
    return axios.get('@bapi/v1/futures/funding_rates', { params });
  },
  // 历史资金费率
  fundingHistory(params: any) {
    return axios.get('@bapi/v1/futures/history_funding_rates', { params });
  },
  // 资金明细
  balanceFlow(params: object) {
    return axios.get('@bapi/v1/futures/asset/balance_flow', { params });
  },
  // 历史委托
  tradeOrders(params: object) {
    return axios.get('@bapi/v1/futures/order/trade_orders', { params });
  },
  // 当前委托
  currentDelegate(params: object) {
    return axios.get('@bapi/v1/futures/order/open_orders', { params });
  },
  // 风险基金
  fundingBalance(params: object) {
    return axios.get('@bapi/v1/futures/insurance_funding_balance', { params });
  },
  // 获取风险保险基金实时余额 - 新增
  getFundingCurrentBalance(params: object) {
    return axios.get('@bapi/v1/futures/insurance_funding_current_balance', { params });
  },
  // 撤单
  orderCancel(data: object) {
    return axios.post('@bapi/v1/futures/order/cancel', data);
  },
  // 价格指数K线
  indexKlines(params: object) {
    return axios.get('@api/quote/v1/index/klines', { params });
  },
  // 组合
  indexConfig(params: object) {
    return axios.get('@api/quote/v1/index/config', { params });
  },
  // 标记价格K线
  markPriceKlines(params: object) {
    return axios.get('@api/quote/v1/markPrice/klines', { params });
  },
  // 溢价指数K线
  premiumKlines(params: object) {
    return axios.get('@api/quote/v1/premium/klines', { params });
  },
  // 订单详情
  getOrderDetails(params: object) {
    return axios.get('@bapi/v1/futures/order/get', { params });
  },
  // 合约订单详情
  futuresOrderMatchInfo(params: object) {
    return axios.get('@bapi/v1/futures/order/match_info', { params });
  },
  // 成交明细
  tradesDetails(params: object) {
    return axios.get('@bapi/v1/futures/order/my_trades', { params });
  },
  // 合约交易对信息
  futuresSymbolsInfo(params: object) {
    return axios.get('@api/v1/basic/symbols', { params });
  },
  // 永续合约资产列表
  futuresAssetAvailable(params: object) {
    return axios.get('@bapi/v1/futures/asset/available', { params });
  },
  // 合约页用资产
  futuresAssetTradeable(params: object) {
    return axios.get('@bapi/v1/futures/asset/tradeable', { params });
  },

  // 获取用户所有合约交易对的杠杆倍数和仓位类型(对象)
  futuresAccountLeverage(params: object) {
    return axios.get('@bapi/v1/futures/account/account_leverage', { params });
  },
  // 获取用户所有合约交易对的杠杆倍数和仓位类型
  futuresOrderSetting(params: object) {
    return axios.get('@bapi/v1/futures/order/get_order_setting', { params });
  },
  // 创建订单
  createFuturesOrder(data: object) {
    return axios.post('@bapi/v1/futures/order/create', data);
  },
  // 获取合约仓位
  getPositionList(params: object) {
    return axios.get('@bapi/v1/futures/order/position', { params });
  },
  // 获取止盈止损订单
  // 获取止盈止损设置
  getStopProfitLoss(params: object) {
    return axios.get('@bapi/v1/futures/order/position/stop_profit_loss/get', { params });
  },
  // 对订单止盈止损
  stopProfitLoss(data: any) {
    return axios.post('@bapi/v1/futures/order/position/stop_profit_loss/set', data);
  },
  // 市价平仓
  closePromptly(data: any) {
    return axios.post('@bapi/v1/futures/order/close_promptly', data);
  },
  // 一键平仓
  closePromptlyAll(data: any) {
    return axios.post('@bapi/v1/futures/order/close_all', data);
  },
  // 调整杠杆倍数
  futuresLeverage(data: any) {
    return axios.post('@bapi/v1/futures/account/adjust_leverage', data);
  },
  // 调整仓位模式
  futuresAdjustType(data: any) {
    // const str = `symbol_id=${data.symbol_id}&margin_type=${data.marginType}`;
    return axios.post('@bapi/v1/futures/account/adjust_type', data);
  },
  // 合约撤单
  futuresOrderCancel(data: any) {
    return axios.post('@bapi/v1/futures/order/cancel', data);
  },
  // 合约全部撤单
  futuresOrderCancelAll(data: any) {
    return axios.post('@bapi/v1/futures/order/cancel_order_list', data);
  },
  // 调整保证金 /asset/modify_margin
  futuresModifyMargin(data: any) {
    return axios.post('@bapi/v1/futures/asset/modify_margin', data);
  },
  // 设置期货下单选项
  setOrderSetting(data: any) {
    return axios.post('@bapi/v1/futures/order/set_order_setting', data);
  },

  // 合约反手提交数据
  sendFuturesReversPosition(data: any) {
    return axios.post('@bapi/v1/futures/order/reverse_position', data);
  },

  // 查看止盈止损订单接口
  getOrderStopProfitLoss(params: object) {
    return axios.get('@bapi/v1/futures/order/stop_profit_loss/get', { params });
  },

  // 修改开仓止盈止损订单接口
  modifyOrderStopProfitLoss(data: any) {
    return axios.post('@bapi/v1/futures/order/stop_profit_loss/modify', data);
  },

  /*
    * 2427 获取合约交易对的预估强平价
    * @symbol_id: ETHUSDT-PERPETUAL
    * @is_long: 0 空仓 | 1 多仓
    * @amount: 增加保证金 - 正数 | 减少保证金 - 负数
    * @return @liquidationPrice
  */
  getLiquidationPrice(params: object) {
    return axios.get('@bapi/v1/futures/calculator/expect_liquidation_price', { params });
  }
};

// 子账户
export const subApi = {
  // 子账户列表
  // /bapi/v1/user/sub/list
  getSubList(params: any) {
    return axios.get('@bapi/v1/user/sub/list', { params });
  },
  // 子账户列表  分页查询
  // /bapi/v1/user/sub/list2
  /**
    * keyword: string; nickname/uid搜索
    * pageNum?: number;页码
    * pageSize?: number;条数
    * userType?: number;5. 交易员 6. 操作员 ,不传为全部
    */
  getSubListPaging(params: any) {
    return axios.get('@bapi/v1/user/sub/list2', { params });
  },
  // 创建子账户
  // POST /bapi/v1/user/sub/create
  subCreateAccount(data: any) {
    return axios.post('@bapi/v1/user/sub/create', data);
  },

  subMountAccount(data: any) {
    return axios.post('@bapi/v1/user/sub/transferTrader', data);
  },

  checkEmail(params: any) {
    return axios.get('@bapi/v1/user/sub/checkExist', { params });
  },
  // 冻结子账户
  // POST /bapi/v1/user/sub/frozen
  frozenAccount(data: any) {
    return axios.post('@bapi/v1/user/sub/frozen', data);
  },
  // 解冻子账户
  unFrozenAccount(data: any) {
    return axios.post('@bapi/v1/user/sub/unfrozen', data);
  },
  // 删除子账户
  delSubAccount(data: any) {
    return axios.post('@bapi/v1/user/sub/del', data);
  },
  // 更新子账户备注
  editRemarkSubAccount(data: any) {
    return axios.post('@bapi/v1/user/sub/remark', data);
  },
  // 强制登出子账户
  logoutSubAccount(data: any) {
    return axios.post('@bapi/v1/user/sub/logout', data);
  },

  // 子账户操作记录
  subAccountOperatorRecord(params: any) {
    return axios.get('@bapi/v1/user/sub/actionLog', { params });
  },

  // 获取子资产信息
  // GET /bapi/v1/asset/sub_user_info
  getSubUserInfo(params: any) {
    return axios.get('@bapi/v1/asset/sub_user_info', { params });
  },

  // 查询apikey可配置权限列表
  getApiKeyAuthList(params: any) {
    return axios.get('@bapi/v1/user/api-key/auth/list', { params });
  },
  // 创建api
  // POST /bapi/v1/user/api-key
  createApiKey(data: any) {
    return axios.post('@bapi/v1/user/api-key', data);
  },
  // 获取api列表
  getApiKeyList(params: any) {
    return axios.get('@bapi/v1/user/page-api-keys', { params });
  },
  // 获取api列表
  getApiKeyDetails(params: any) {
    return axios.get('@bapi/v1/user/api-key/' + params.id, { params });
  },
  // 删除API
  // POST /bapi/v1/user/api-key/delete/{id}
  deleteApiKey(data: any) {
    return axios.post('@bapi/v1/user/api-key/delete/' + data.id, data);
  },
  // 更新api状态
  // POST /bapi/v1/user/api-key/change-status/{id}
  changeApiKeyStatus(data: any) {
    return axios.post('@bapi/v1/user/api-key/change-status/' + data.id, data);
  },
  // 检查ip
  // POST /bapi/v1/user/api-key/check-ips/{id}
  checkIp(data: any) {
    return axios.post('@bapi/v1/user/api-key/check-ips', data);
  },
  // 更新Ip
  // POST /bapi/v1/user/api-key/update-ips/{id}
  updateIp(data: any) {
    return axios.post('@bapi/v1/user/api-key/update-ips/' + data.id, data);
  },
  // 编辑api
  // POST /bapi/v1/user/api-key/update
  updateApiKey(data: any) {
    return axios.post('@bapi/v1/user/api-key/update', data);
  },
  // apiAmountLimit 获取创建api key上限
  getAPIAmountLimit(params: any) {
    return axios.get('@bapi/v1/user/apikey-count', { params });
  },
  // 获取API第三方充值列表
  getApiKeyBrokers(params: any) {
    return axios.get('@bapi/v1/user/api-key/brokers', { params });
  },
  // 查询api经纪商信息
  getQueryApiBrokerInfo(params:any) {
    return axios.get('@bapi/v1/user/api-broker-info', { params });
  }
};

export const cardApi = {
  // 卡劵列表
  getActivitiesExperience(params: any) {
    return axios.get('@bapi/v1/activities/experience', { params });
  }
};
// 活动
export const activityApi = {
  // 新手任务获取用户状态
  getNewUserTaskStatus() {
    return axios.get('@bapi/v3/events/novice/task/status');
  },
  // 240U大礼包
  experience_card_info(params: any) {
    return axios.get('@bapi/v1/activities/experience_card_info', { params });
  },
  // 开业盲盒 获取活动状态
  getBlindBoxInfo() {
    return axios.get('@bapi/v1/activity/opening/blindbox/info');
  },
  // EXCHANGE提交CRM 客户数据相关接口 type： 2api客户奖励，3八八庆典
  exchangeSubmitCrm(params: any) {
    return axios.post('@bapi/v1/activities/customer/submit', params, {
    //   headers: { 'Content-Type': 'application/json' }
    });
  },
  // 获取88庆典活动kyc状态  schedule 1完成认证在活动前，2完成完成认证时间在活动期间内
  getCelebrationKycStatus() {
    return axios.get('@bapi/v3/kyc/common/kyc_activity', {});
  },
  // 获取88庆典活动状态是否结束  // data=false代表活动有效，
  getCelebrationTimeStatus() {
    return axios.get('@bapi/v3/kyc/promo/kyc_activity_status', {});
  },
  // 获取88庆典活动info
  getCelebrationInfo() {
    return axios.get('@bapi/v3/kyc/promo/kyc_activity_info', {});
  },
  //     /bapi/v3/kyc/campaign/reward/status 查询API奖励活动状态
  getCampaignRewardStatus() {
    return axios.get('@bapi/v3/kyc/campaign/reward/status', {});
  },
  // /bapi/v3/kyc/campaign/reward/user/apikey 查询用户是否已经有api key 需要登陆的
  getCampaignRewardUserApikey() {
    return axios.get('@bapi/v3/kyc/campaign/reward/user/apikey', {});
  },
  // 新手任务 - 获取用户任务状态
  getNewbieTaskInfo() {
    return axios.get('@bapi/v1/tasks/newbie/task/info');
  },
  // 新手任务 - 参与任务
  startNewbieTask() {
    return axios.post('@bapi/v1/tasks/newbie/task/start');
  },
  // 新手任务 - 完成任务
  completeNewbieTask() {
    return axios.post('@bapi/v1/tasks/newbie/task/complete');
  },
  // 4380 完成银行认证送hsk弹窗 -返回当前用户的弹窗信息
  getKYCCampaignFiatWindow() {
    return axios.get('@bapi/v3/kyc/campaign/fiat/window', {});
  },
  // 4380 完成银行认证送hsk弹窗 -通知后端已经弹窗
  postKYCCampaignFiatWindow() {
    return axios.post('@bapi/v3/kyc/campaign/fiat/window', {});
  },
  getCampaignCommonSwitch(params: any) { // 活动弹框统一配置
    return axios.get('@bapi/v3/kyc/campaign/common/switch', { params });
  },
  // 邀请有奖用户获奖明细列表
  getInviteUserRewardList(params: any) {
    return axios.get('@bapi/v3/invite/reward/list', { params });
  },
  // 用户邀请明细列表
  getInviteUserList(params: any) {
    return axios.get('@bapi/v3/invite/user/list', { params });
  },
  // 邀请有奖大使额外奖励列表
  getInviteChanelRewardList(params: any) {
    return axios.get('@bapi/v3/invite/common/activity/chanel/rewardList', { params });
  },
  // 邀请有奖获取活动信息接口
  getInviteActivityInfo() {
    return axios.get('@bapi/v3/invite/common/activity/info');
  },
  getEventInfo(name: any) {
    return axios.get(`@bapi/v3/events/${name}`);
  },
  // 5662 点击个人认证，将客户的信息同步到CRM公海中, 归类为个人；来源：PI开户送HSK活动
  postPIOpen() {
    return axios.post('@bapi/v1/activities/customer/pi/open', {});
  },
  // T G Ton活动弹窗信息
  getTgTonNotification() {
    return axios.get('@bapi/v3/tg/notification');
  },
  // TG Ton活动弹窗点击确认
  submitTgTonNotification(params: any) {
    return axios.post('@bapi/v3/tg/sub-notification', params, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 5730 创世VIP 接口文档：
  // https://hashkeydag.sg.larksuite.com/wiki/LyiqwX0PEi9ioAkfSa9lrQHag8f
  vip: {
    // 获取当前活动轮次
    // 此接口专为创世vip活动开发，因此不需再通过activityApi.getEventInfo('genesisVip')取数据
    // extra移至round/list中
    // 展示数据方法可参考@/pages/activity/web/vip/index.vue
    getEventRoundList() {
      return axios.get('@bapi/v3/genesis/vip/round/list');
    },
    // 按roundId获取当前轮次详情，extra信息配置在此接口中
    // roundId       轮次id
    getRoundInfo(params: any) {
      return axios.get('@bapi/v3/genesis/vip/round/info', { params });
    },
    // 订阅活动信息
    // roundId       轮次id
    // subScribeType 订阅类型, 0：取消订阅 1：订阅
    subscribeEvent(params: any) {
      return axios.post('@bapi/v3/genesis/vip/subscribe', params, {
        headers: { 'Content-Type': 'application/json' }
      });
    },
    // 参与认购
    // verifyCodeType: 42,  // 2FA验证场景      // 2FA验证场景
    // emailCode: "xxx",
    // emailOrderId: "xx",
    // mobileCode: "xx",
    // mobileOrderId: "xx",
    // gaCode: 111222,
    // roundId: 1, // 轮次id
    // goldNum: 1, // 金卡数量
    // silverNum: 2, // 银卡数量
    // tokenId: "USD" // 支付币种
    // amount: "1200" // 支付数量
    buy(params: any) {
      return axios.post('@bapi/v3/genesis/vip/buy', params, {
        headers: { 'Content-Type': 'application/json' }
      });
    },
    // 查询抽签结果（个人是否中签）
    // roundId
    getDrawResult(params: any) {
      return axios.get('@bapi/v3/genesis/vip/draw/result', { params });
    },
    // 记录查看中签通知，请求后不再显示中签弹窗
    // roundId
    postDrawResult(params: any) {
      return axios.post('@bapi/v3/genesis/vip/view/result', params);
    },
    // 获取中签名单（上一轮中签人名单）
    // roundId: 轮次id，必需
    // cardType：卡类型，必需
    // pageNo: 页数，可选，默认1
    // pageSize: 分页大小，可选，默认20
    getWinnerList(params: any) {
      return axios.get('@bapi/v3/genesis/vip/winner/list', { params });
    },
    // 查询用户订单列表（抢购历史）
    // roundId：轮次id，可选
    // pageNum: 页数，可选，默认1
    // pageSize:  分页大小，可选，默认20
    getOrderList(params: any) {
      return axios.get('@bapi/v3/genesis/vip/order/list', { params });
    },
    // 查询卡片列表（我的卡片）
    // cardType：卡类型，可选
    // pageNum: 页数，可选，默认1
    // pageSize:  分页大小，可选，默认20
    getCards(params: any) {
      return axios.get('@bapi/v3/genesis/vip/card/list', { params });
    },
    // 2FA
    // "cardNo": 111 // 卡号，必传
    // "receiveUserId": 111 // 接收人id
    transfer(params: any) {
      return axios.post('@bapi/v3/genesis/vip/transfer', params, {
        headers: { 'Content-Type': 'application/json' }
      });
    },
    // historyType：记录类型，可选，默认0
    // - 0： 全部
    // - 1：购买
    // - 2：受赠
    // - 3：转赠
    // - 4：退款
    // pageNum: 页数，可选，默认1
    // pageSize:  分页大小，可选，默认20
    cardHistory(params: any) {
      return axios.get('@bapi/v3/genesis/vip/card/history', { params });
    }
  },
  // 5730 创世VIP end
  // 交易挖矿
  mining: {
    // 获取交易挖矿活动信息
    getEventInfo() {
      return axios.get('@bapi/v3/trade/mining/event/info');
    },
    // 报名参与交易挖矿活动
    applyEvent() {
      return axios.post('@bapi/v3/trade/mining/event/apply', undefined, {
        headers: { 'Content-Type': 'application/json' }
      });
    },
    // 获取某日奖励信息
    // "sendDate": 20240308 // 发放日期，yyyyMMdd
    getEventRewardInfo(params: any) {
      return axios.get('@bapi/v3/trade/mining/event/reward/info', { params });
    },
    // 获取奖励信息列表
    // "page": 1, // 页数
    // "pageSize": 10 // 每页数量
    getEventRewardList(params: any) {
      return axios.get('@bapi/v3/trade/mining/event/reward/list', { params });
    },
    // 获取Api交易挖矿活动信息
    getApiEventInfo() {
      return axios.get('@bapi/v3/trade/mining/api/event/info');
    },
    // 报名参与Api交易挖矿活动
    applyApiEvent() {
      return axios.post('@bapi/v3/trade/mining/api/event/apply', undefined, {
        headers: { 'Content-Type': 'application/json' }
      });
    },
    // 查询API交易活动周列表
    getApiWeekList() {
      return axios.get('@bapi/v3/trade/mining/api/event/week/list');
    },
    // 获取每周奖池配置信息
    // "week": 1
    getApiPoolInfo(params: any) {
      return axios.get('@bapi/v3/trade/mining/api/event/pool/info', { params });
    },
    // 查询API交易指定某一周排名信息
    // "week": 1
    getApiRankList(params: any) {
      return axios.get('@bapi/v3/trade/mining/api/event/rank/list', { params });
    },
    // 获取api奖励信息列表
    // "page": 1, // 页数
    // "pageSize": 10 // 每页数量
    getApiEventRewardList(params: any) {
      return axios.get('@bapi/v3/trade/mining/api/event/reward/list', { params });
    },
    // 今日发放奖励
    // 10秒一刷新
    getTodayReward() {
      return axios.get('@bapi/v3/trade/mining/event/today/reward');
    },
    getApiRankInfo(params: any) {
      return axios.get('@bapi/v3/trade/mining/api/event/rank/info', { params });
    },
    // 获取是否展示弹窗
    getEventNotification() {
      return axios.get('@bapi/v3/trade/mining/event/notification');
    },
    // 提交弹窗展示结果
    submitEventNotification() {
      return axios.post('@bapi/v3/trade/mining/event/notification/submit', undefined, {
        headers: { 'Content-Type': 'application/json' }
      });
    }
  },
  // 交易挖矿 end

  // 合约交易赛
  contractTrading: {
    // 获取交易挖矿活动信息
    getEventInfo() {
      return axios.get('@bapi/v3/futures/trade/mining/event/info');
    },
    // 报名参与交易挖矿活动
    applyEvent() {
      return axios.post('@bapi/v3/futures/trade/mining/event/apply', undefined, {
        headers: { 'Content-Type': 'application/json' }
      });
    },
    // 获取某日奖励信息
    // "sendDate": 20240308 // 发放日期，yyyyMMdd
    getEventRewardInfo(params: any) {
      return axios.get('@bapi/v3/futures/trade/mining/event/reward/info', { params });
    },
    // 获取奖励信息列表
    // "page": 1, // 页数
    // "pageSize": 10 // 每页数量
    getEventRewardList(params: any) {
      return axios.get('@bapi/v3/futures/trade/mining/event/reward/list', { params });
    },
    // 获取Api交易挖矿活动信息
    getApiEventInfo() {
      return axios.get('@bapi/v3/futures/trade/mining/api/event/info');
    },
    // 报名参与Api交易挖矿活动
    applyApiEvent() {
      return axios.post('@bapi/v3/futures/trade/mining/api/event/apply', undefined, {
        headers: { 'Content-Type': 'application/json' }
      });
    },
    // 查询API交易活动周列表
    getApiWeekList() {
      return axios.get('@bapi/v3/futures/trade/mining/api/event/week/list');
    },
    // 获取每周奖池配置信息
    // "week": 1
    getApiPoolInfo(params: any) {
      return axios.get('@bapi/v3/futures/trade/mining/api/event/pool/info', { params });
    },
    // 查询API交易指定某一周排名信息
    // "week": 1
    getApiRankList(params: any) {
      return axios.get('@bapi/v3/futures/trade/mining/api/event/rank/list', { params });
    },
    // 获取api奖励信息列表
    // "page": 1, // 页数
    // "pageSize": 10 // 每页数量
    getApiEventRewardList(params: any) {
      return axios.get('@bapi/v3/futures/trade/mining/api/event/reward/list', { params });
    },
    // 今日发放奖励
    // 10秒一刷新
    getTodayReward() {
      return axios.get('@bapi/v3/futures/trade/mining/event/pool/status');
    },
    getApiRankInfo(params: any) {
      return axios.get('@bapi/v3/futures/trade/mining/api/event/rank/info', { params });
    },
    // 获取是否展示弹窗
    getEventNotification() {
      return axios.get('@bapi/v3/futures/trade/mining/event/notification');
    },
    // 提交弹窗展示结果
    submitEventNotification() {
      return axios.post('@bapi/v3/futures/trade/mining/event/notification/submit', undefined, {
        headers: { 'Content-Type': 'application/json' }
      });
    }
  },
  // 合约交易赛 end

  // 合约收益率排名赛
  futuresFieldTrading: {
    // 获取用户活动状态
    getUserInfo() {
      return axios.get('@bapi/v3/futures/yield/user/info');
    },
    // 报名
    apply() {
      return axios.post('@bapi/v3/futures/yield/apply', undefined, {
        headers: { 'Content-Type': 'application/json' }
      });
    },
    // 获取某天排行榜信息
    // day: 排行榜日期，yyyyMMdd
    getRankList(params: any) {
      return axios.get('@bapi/v3/futures/yield/rank/list', { params });
    },
    // 获取个人奖励记录
    //  page: 默认1
    //  pageSize: 默认10
    getRewardList(params: any) {
      return axios.get('@bapi/v3/futures/yield/reward/list', { params });
    }
  },

  // 社区交易赛
  communityTrading: {
    // 获取活动信息
    // activityId: 活动id
    getActivityInfo(params: any) {
      return axios.get('@bapi/v3/community/trade-mining/activity-info', { params });
    },
    // 获取排名信息
    // activityId: 活动id
    getRankInfo(params: any) {
      return axios.get('@bapi/v3/community/trade-mining/rank-info', { params });
    },
    // 获取入口信息
    getEntryInfo() {
      return axios.get('@bapi/v3/community/trade-mining/entry-info');
    }
  },

  // Launch
  launch: {
    // 获取当前项目信息
    getCurrentProjectInfo() {
      return axios.get('@bapi/v3/launch/project/current/info');
    },
    // 获取launchpool进行中的项目列表
    getCurrentProjectList() {
      return axios.get('@bapi/v3/launch/project/current/list');
    },
    // 获取launchpool项目信息
    // "projectId": 1, //项目id
    getProjectInfo(params: any) {
      return axios.get('@bapi/v3/launchpool/project/info', { params });
    },
    // 获取用户参与项目信息
    // "projectId": 1, //项目id
    getProjectUserInfo(params: any) {
      return axios.get('@bapi/v3/launchpool/project/user/info', { params });
    },
    // 获取launch统计信息
    getStatsInfo() {
      return axios.get('@bapi/v3/launch/stats/info');
    },
    // 获取launch项目配置信息
    // "projectId": 1, //项目id
    // "projectType": 1, //项目类型 1:launchpool 2:launchpad
    geProjectConfig(params: any) {
      return axios.get('@bapi/v3/launch/project/config', { params });
    },
    // 质押
    // "poolId": 1, //资金池id
    // "amount": "150", //数量
    pledge(params: any) {
      return axios.post('@bapi/v3/launchpool/pledge', params, {
        headers: { 'Content-Type': 'application/json' }
      });
    },
    // 合约质押报名
    // "poolId": 1, //资金池id
    apply(params: any) {
      return axios.post('@bapi/v3/launchpool/pledge/apply', params, {
        headers: { 'Content-Type': 'application/json' }
      });
    },
    // 赎回
    // "poolId": 1, //资金池id
    // "amount": "150", //数量
    redeem(params: any) {
      return axios.post('@bapi/v3/launchpool/redeem', params, {
        headers: { 'Content-Type': 'application/json' }
      });
    },
    // 获取邀请分享信息
    // "projectId": 1, //项目id
    // "projectType": 1, //项目类型 1:launchpool 2:launchpad
    getInviteShareInfo(params: any) {
      return axios.get('@bapi/v3/launch/project/invite/share/info', { params });
    },
    // 获取当前邀请奖励信息
    // "active" : 1 进行中的 2 过往的
    getInviteInfo(params: any) {
      return axios.get('@bapi/v3/launch/invite/info', { params });
    },
    // 获取邀请列表
    // "active" : 1 进行中的 2 过往的
    // "page": 1, // 页数
    // "pageSize": 10, // 每页数量
    getInviteList(params: any) {
      return axios.get('@bapi/v3/launch/invite/list', { params });
    },
    // 获取质押币种列表
    // "projectId": 1, //项目id
    getUserPledgeTokenList(params: any) {
      return axios.get('@bapi/v3/launchpool/pledge/token/list', { params });
    },
    // 质押日历获取用户质押金额
    // "poolId": 1, //资金池id
    getUserPledgeAmount(params: any) {
      return axios.get('@bapi/v3/launchpool/pledge/amount', { params });
    },
    // 质押日历获取质押快照
    // "projectId": 1, //项目id
    // "poolId": 1, //资金池id
    // "page": 1, // 页数
    // "pageSize": 10, // 每页数量
    getPledgeSnapshotList(params: any) {
      return axios.get('@bapi/v3/launchpool/pledge/snapshot/list', { params });
    },
    // 用户中心 - launchpool当前质押信息
    getUserPledgeInfo() {
      return axios.get('@bapi/v3/launchpool/pledge/info');
    },
    // 用户中心-launchpool当前质押列表
    // "page": 1, // 页数
    // "pageSize": 10, // 每页数量
    // 以上参数均为非必传
    getUserPledgeList(params: any) {
      return axios.get('@bapi/v3/launchpool/pledge/list', { params });
    },
    // 用户中心-launchpool质押记录列表
    // "page": 1, // 页数
    // "pageSize": 10, // 每页数量
    // "startTime": 1711324800000, // 开始时间，毫秒级时间戳
    // "endTime": 1711324800000, // 结束时间，毫秒级时间戳
    // "type": 1, // 订单类型 1: 质押 2: 赎回 3:奖励
    // "projectName": "abc" //项目名称mine
    getUserOrderList(params: any) {
      return axios.get('@bapi/v3/launchpool/order/list', { params });
    },
    // 获取订阅消息状态
    getSubscriptionInfo() {
      return axios.get('@bapi/v3/launch/subscription/info');
    },
    // 订阅消息
    // "subscription": 1, // 0 取消订阅 1 订阅
    subscription(params: any) {
      return axios.post('@bapi/v3/launch/subscription', params, {
        headers: { 'Content-Type': 'application/json' }
      });
    },
    // 获取 launchpool 历史
    getLaunchpoolHistory(params: any) {
      return axios.get('@bapi/v3/launch/project/history/list', { params });
    },
    // 额外奖励信息
    getExtraRewardInfo() {
      return axios.get('@bapi/v3/launchpool/extra/reward/info');
    },
    // 用户额外奖励信息
    getUserExtraRewardInfo() {
      return axios.get('@bapi/v3/launchpool/extra/reward/user/info');
    },
    // 代理返佣-受邀人返佣比例
    getUserRewardInfo() {
      return axios.get('@bapi/v1/invite/agent/rebate/invitee/info');
    }
  },
  // Launch end
  // 0费率
  zeroRating: {
    // 活动信息
    getZeroRatingInfo(params: any) {
      return axios.get('@api/v1/zeroRating/info', { params });
    },
    // 奖励详情列表
    getRewardList(params: any) {
      return axios.get('@api/v1/zeroRating/reward/details', { params });
    },
    // 我的奖励
    getRewardInfo(params: any) {
      return axios.get('@api/v1/zeroRating/reward/info', { params });
    },
    // 预约
    zeroRatingSubscribe(params: any) {
      return axios.post('@api/v1/zeroRating/subscribe', params, {
        headers: { 'Content-Type': 'application/json' }
      });
    }
  },
  // 0费率 end
  // 新手任务二期
  newbieTasks: {
    // 活动详情
    getActivityInfo() {
      return axios.get('@bapi/v3/events/novice/newtask/status');
    },
    // 奖励明细
    getRewardDetail() {
      return axios.get('@bapi/v3/events/novice/newtask/reward/detail');
    },
    // 提现申请
    submitWithdraw(params: any) {
      return axios.post('@bapi/v3/events/novice/newtask/withdrawal/submit', params, {
        headers: { 'Content-Type': 'application/json' }
      });
    }
  }
  // 新手任务二期 end
};
// 代理人
export const agentApi = {
  // 代理人首页Dashboard
  getAgentDashboard(params: any) {
    return axios.get('@bapi/v1/agent/home/dashboard', { params });
  },

  // 代理人查询默认返佣配置
  getAgentBonusInfo() {
    return axios.get('@bapi/v1/agent/bonus/info');
  },

  // 查询所有邀请码
  getAgentBonusList(params: any) {
    return axios.get('@bapi/v1/agent/bonus/list', { params });
  },

  // 创建邀请码
  createAgentBonus(params: any) {
    return axios.post('@bapi/v1/agent/bonus/add', params, {
      headers: { 'Content-Type': 'application/json' }
    });
  },

  // 查询子代理人列表
  getAgentQueryBroker(params: any) {
    return axios.get('@bapi/agent/query_broker', { params });
  },

  // 分佣收入-汇总列表
  getAgentCommissionInfo(params: any) {
    return axios.get('@bapi/v1/agent/commission/bonus/info', { params });
  },

  // 分佣收入-明细列表
  getAgentCommissionList(params: any) {
    return axios.get('@bapi/v1/agent/commission/bonus/list', { params });
  },

  // 我的客户列表查询
  getDirectList(params: any) {
    return axios.get('@bapi/v1/invitation/direct-customer/list', { params });
  },

  // 我的客户-dashboard
  getDirectAggregation(params: any) {
    return axios.get('@bapi/v1/invitation/direct-customer/aggregation', { params });
  },

  // 数据表现-子代理列表查询
  getSubAgentList(params: any) {
    return axios.get('@bapi/v1/agent/sub-agent-data/list', { params });
  },

  // 数据表现-子代理-dashboard
  getSubAgentAggregation() {
    return axios.get('@bapi/v1/agent/sub-agent-data/dashboard');
  },

  // 数据表现-活动数据列表查询
  getActivityList(params: any) {
    return axios.get('@bapi/v1/agent/activity-data/list', { params });
  },

  // 数据表现-活动数据-dashboard
  getActivityAggregation(params: any) {
    return axios.get('@bapi/v1/agent/market-activity/dashboard', { params });
  },

  // 活动查询
  getActivity(params: any) {
    return axios.get('@bapi/v1/activities', { params });
  },

  // 创建/升级代理人
  createAgentAddSubAgent(params: any) {
    return axios.post('@bapi/agent/add_sub_agent', params);
  },

  // 重新申请代理人
  agentReaddSubAgent(params: any) {
    return axios.post('@bapi/agent/readd_sub_agent', params);
  },

  // 首页banner查询
  getAgentBanners() {
    return axios.get('@bapi/v1/agent/home/banners');
  },

  // 下载页banner查询
  getBasicBanners(params: any)  {
    return axios.get('@bapi/v1/basic/banners', { params });
  },

  // 首页大banner查询
  getHomeBanner()  {
    return axios.get('@bapi/v1/basic/web/home/banners');
  },

  // 活动banner查询
  getAgentActivityBanner(params: any) {
    return axios.get('@bapi/v1/agent/home/banner', { params });
  },

  // 子代理返佣k线
  getAgentSubAgentLine(params: any) {
    return axios.get('@bapi/v1/agent/sub-agent/bonus/line', { params });
  },

  // 直客返佣k线
  getAgentDirectCustomerLine(params: any) {
    return axios.get('@bapi/v1/agent/direct-customer/bonus/line', { params });
  },

  // 代理人配置接口
  getAgentConfig() {
    return axios.get('@bapi/v1/agent/config');
  },

  // 设置默认邀请码
  setAgentBonusDefault(params: any) {
    return axios.post(`@bapi/v1/agent/bonus/update-default/${params.id}`, {
      headers: { 'Content-Type': 'application/json' }
    });
  },

  // 修改邀请码配置
  updateAgentBonus(params: any) {
    const id = params.id;
    delete params.id;
    return axios.post(`@bapi/v1/agent/bonus/update/${id}`, params, {
      headers: { 'Content-Type': 'application/json' }
    });
  },

  // 解绑代理人
  cancelSubAgent(params: any) {
    return axios.post('@bapi/agent/cancel_sub_agent', params);
  },

  // 代理人查询详情
  getAgentReaddSub(params: any) {
    return axios.get('@bapi/agent/info', { params });
  },

  // 代理人获取邀请码
  getAgentGenerate() {
    return axios.get('@bapi/v1/agent/bonus/generate');
  },

  // 获取所有活动
  getAgentActivityList() {
    return axios.get('@bapi/v1/agent/activity/list');
  },

  // 历史活动数据
  getAgentMarketActivityList(params: any) {
    return axios.get('@bapi/v1/agent/market-activity/list', { params });
  },

  // 设置活动专属链接
  marketActivityConfigLink(params: any) {
    return axios.post('@bapi/v1/agent/market-activity/config-link', params, {
      headers: { 'Content-Type': 'application/json' }
    });
  },

  // 活动tab
  getAgentActivityTab() {
    return axios.get('@bapi/v1/agent/activity/tab');
  }
};

export const affiliates = {
  // 获取当前IP对应的国家
  getCurrentCountry() {
    return axios.get('@bapi/v1/basic/country/current_ip');
  },
  apply(data: any) {
    return axios.post('@bapi/v1/agent/apply', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 邮箱检验
  validateEmail(data: any) {
    return axios.post('@bapi/v1/agent/apply/check', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
};
// vip
export const vipApi = {
  levelConfigs() {
    return axios.get('@bapi/v1/user/level-info');
  },
  feeConfig(params: any) {
    return axios.get('@bapi/b1/broker/fee/config', { params });
  }
};

// ip黑名单
export const ip = {
  ipBlackList() {
    return axios.get('@bapi/v1/basic/ip-black-list');
  }
};
export const copyTrading = {
  // 交易员列表
  getLeaders(params: any) {
    return axios.get('@bapi/v1/copy-trading/leaders', { params });
    // return axios.get('@mock/api/v1/copy-trading/leaders', { params });
  },
  // 交易员详情（客态）
  getLeaderInfo(params: any) {
    return axios.get('@bapi/v1/copy-trading/leader-detail', { params });
    // return axios.get('@mock/api/v1/copy-trading/leader-detail', { params });
  },
  // 交易员详情（主态）
  getLeaderSelfInfo(params: any) {
    return axios.get('@bapi/v1/copy-trading/my-lead-detail', { params });
    // return axios.get('@mock/api/v1/copy-trading/my-lead-detail', { params });
  },
  // 我的跟单详情
  getFollowInfo(params: any) {
    return axios.get('@bapi/v1/copy-trading/my-follow-detail', { params });
    // return axios.get('@mock/api/v1/copy-trading/my-follow-detail', { params });
  },
  // 我的跟单设置
  getMyFollowConfig(params: any) {
    return axios.get('@bapi/v1/copy-trading/my-follow-config', { params });
  },

  // 是否为交易员
  getCheckLeader() {
    // return axios.get('@mock/api/v1/copy-trading/check-leader');
    return axios.get('@bapi/v1/copy-trading/check-leader');
  },

  // 当前带单
  getCurrentLeadOrders(params: any) {
    return axios.get('@bapi/v1/copy-trading/current-lead-orders', { params });
  },
  // 历史带单
  getHistoryLeadOrders(params: any) {
    return axios.get('@bapi/v1/copy-trading/history-lead-orders', { params });
  },
  // 客态 跟随者
  getFollowers(params: any) {
    return axios.get('@bapi/v1/copy-trading/top-followers', { params });
  },
  // 主态 分润
  getMySharingProfit(params: any) {
    return axios.get('@bapi/v1/copy-trading/my-sharing-profit', { params });
  },
  // 主态 我的跟单用户
  getMyCurrentFollowers(params: any) {
    return axios.get('@bapi/v1/copy-trading/my-current-followers', { params });
  },
  // 我的交易员
  getMyCurrentLeader(params: any) {
    return axios.get('@bapi/v1/copy-trading/my-current-leader', { params });
  },
  // 当前跟单仓位
  getCurrentFollowPositions(params: any) {
    return axios.get('@bapi/v1/copy-trading/current-follow-positions', { params });
  },
  // 历史跟单
  getHistoryFollowOrder(params: any) {
    return axios.get('@bapi/v1/copy-trading/history-follow-orders', { params });
  },
  // /api/v1/copy-trading/cancel-follow
  // 取消跟随
  cancelFollow(data: any) {
    return axios.post('@bapi/v1/copy-trading/cancel-follow', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 取消跟随 前置查询 是否允许取消
  hasFollowingOrder(params: any) {
    return axios.get('@bapi/v1/copy-trading/has-following-order', { params });
  },

  followCopyTrading(params: any) {
    return axios.post('@bapi/v1/copy-trading/follow', params, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  closePosition(params: any) {
    return axios.post('@bapi/v1/copy-trading/close-position', params, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 跟单活动时间
  activitiesInfo(params: any) {
    return axios.get('@bapi/v1/activities/info', { params });
  }

};

export const fiatApi = {
  // 获取支持法币
  getFiatCurrency() {
    return axios.get('@bapi/v1/fiat/basic/fiat-currency');
  },

  // 获取支持加密币
  getCryptoCurrency() {
    return axios.get('@bapi/v2/basic/crypto-currency');
  },

  // 获取所有渠道
  getFiatChannels() {
    return axios.get('@bapi/v1/fiat/basic/channels');
  },

  // 查询订单
  getFiatOrders(params: any) {
    return axios.get('@bapi/v1/fiat/orders', { params });
  },

  // 批量报价
  getFiatBatchQuote(params: any) {
    return axios.get('@bapi/v1/fiat/batch-quote', { params });
  },

  // 下单
  createFiatOrder(data: any) {
    return axios.post('@bapi/v1/fiat/create-order', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },

  getBankList(data: any) {
    return axios.post('@api/exchange/api/cfs/fiat/client/bank/account/queryList', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },

  deleteBank(data: any) {
    return axios.post('@api/exchange/api/cfs/fiat/client/bank/account/delete', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },

  getFiatTransferOrder(data: any) {
    return axios.post('@bapi/v3/asset/flow/query', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },

  getFiatOrder(data: any) {
    return axios.post('@api/exchange/api/cfs/fiat/order/account/queryOrderList', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  getBankINfoByOrder(params: object) {
    return axios.get('@api/exchange/api/cfs/fiat/client/bank/account/queryByOrderId', { params });
  },

  exportOrderList(params: any) {
    return axios.get('@api/exchange/api/cfs/fiat/order/account/export', { params, responseType: 'blob' }
    );
  },
  // 法币充值FAQ文章链接列表 - 充值页 - new
  getFaq(params: object) {
    return axios.get('@api/exchange/api/cfs/fiat/faq/list', { params });
  },
  // 获取系统可配置资产 - 充值页 -new
  getKycTokenConfig() {
    return axios.get('@api/exchange/api/cfs/fiat/asset/kycTokenConfig');
  },
  // 获取用户可充值币种列表 - 充值页 -new
  // getDepositAssetList() {
  //   return axios.get('@api/exchange/api/cfs/fiat/asset/list');
  // },
  // // 获取币种手续费 - 充值页 - new   --> 改为从 basic/tokens 内获取充值手续费
  // getDepositAssetFeeList(data: any) {
  //   return axios.post('@api/exchange/api/cfs/fiat2/deposit/queryFiatDepositFeeConfig-v2', data, {
  //     headers: { 'Content-Type': 'application/json' }
  //   });
  // },
  // 获取VA账号信息 - 充值页 - new
  getVirtualAccountInfo(params: object = {}) {
    return axios.get('@api/exchange/api/cfs/fiat2/deposit/getVirtualAccount', { params });
  },
  // 账户记录 - 法币充值订单列表查询 - 充值 - new
  getDepositList(data: any) {
    return axios.post('@api/exchange/api/cfs/fiat2/order/deposit/records', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 账户记录 - 通过orderId查看订单明细 - 充值 - new
  getDepositDetail(data: any) {
    return axios.post('@api/exchange/api/cfs/fiat2/order/deposit/detail', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 账户记录 - 下载法币充值订单 - 充值-快速转账 - new
  downloadDepositList(params: object) {
    return axios.get('@api/exchange/api/cfs/fiat2/order/records/export', { params, responseType: 'blob' });
  },
  // 账户记录 - 法币提现订单记录列表查询 - 提现 - new
  getWithdrawList(data: any) {
    return axios.post('@api/exchange/api/cfs/fiat2/order/withdraw/records', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 账户记录 - 通过orderId查看订单明细 - 提现 - new
  getWithdrawDetail(data: any) {
    return axios.post('@api/exchange/api/cfs/fiat2/order/withdraw/detail', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 账户记录 - 导出法币提现订单记录 - 提现-快速转账 - new
  downloadWithdrawList(params: object) {
    return axios.get('@api/exchange/api/cfs/fiat2/order/withdraw/records/export', { params, responseType: 'blob' });
  },
  // 查看系统法币通道状态
  getFiatOsStatus(params: object) {
    // return axios.get('@api/exchange/api/cfs/fiat2/bank/channel/status', {  params });
  },
  // 查询法币通道
  getChannelList() {
    // return axios.get('@api/exchange/api/cfs/fiat2/bank/channel/list');
  },
  // 获取充值/退款注意事项说明 category = DepositAttention / RefundReason
  getNote(params: object) {
    return axios.get('@api/exchange/api/cfs/fiat2/dict/listByCategory', {  params });
  }
};
  // hsk积分接口
export const hskApi = {
  // 查询用户积分总量
  getUserPoint() {
    return axios.get('@bapi/v3/point/common/query/user_point');
  },
  // 查询用户积分明细记录
  getPointRecord(params: object) {
    return axios.get('@bapi/v3/point/common/query/point_record', { params });
  },
  // 查询hsk积分获取类型
  getReferenceByPoint(params: object) {
    return axios.get('@bapi/v3/kyc/common/reference', { params });
  },
  // 查询用户积分子明细记录
  getSubPointRecord(params: object) {
    return axios.get('@bapi/v3/point/common/query/sub_point_record', { params });
  },
  // 查询交易送HSK活动状态
  getTradeHskStatus() {
    return axios.get('@bapi/v3/kyc/campaign/transaction/status');
  },
  // 查询交易送HSK用户信息
  getTradeHskUserStatus() {
    return axios.get('@bapi/v3/kyc/campaign/transaction/user/status');
  },
  // 参加活动
  goToJoin() {
    return axios.post('@bapi/v3/kyc/campaign/transaction/user');
  },
  // 2024 迎新春 - 查询交易送HSK活动状态
  getNewYearTradeHskStatus() {
    return axios.get('@bapi/v3/events/trading/info');
  },
  // 2024 迎新春 - 查询交易送HSK用户信息
  getNewYearTradeHskUserStatus() {
    return axios.get('@bapi/v3/events/trading/detail');
  },
  // 2024 迎新春 - 参加活动
  goNewYearToJoin() {
    return axios.post('@bapi/v3/events/trading/apply');
  },
  // hsk管理: 查询用户HSK总量: 总数量 + 待解锁 + 已解锁
  getUserHskAmount() {
    return axios.get('@bapi/v3/point/common/query/user_point_statistic');
  },
  // hsk管理: 根据时间查询用户解锁计划 - 存在分页{pageNo: 1, pageSize: 10}
  getUserPlanByTime(params: object) {
    return axios.get('@bapi/v3/airdropplan/query/bytime', { params });
  },
  // hsk管理: 根据活动查询用户解锁计划 - 存在分页{pageNo: 1, pageSize: 10}
  getUserPlanByActivity(params: object) {
    return axios.get('@bapi/v3/airdropplan/query/byactivity', { params });
  },
  // hsk管理: 指定解锁日期查询数据
  getListByIssuanceDate(params: object) {
    return axios.get('@bapi/v3/airdropplan/query/byIssuanceDate', { params });
  },
  // hsk管理: 根据用户解锁批次列表
  getListByBatchdate(params: object) {
    return axios.get('@bapi/v3/airdropplan/query/batchdate', { params });
  },
  // hsk管理: 根据解锁批次查询数据
  getListByActiveIdBatchDate(params: object) {
    return axios.get('@bapi/v3/airdropplan/query/byBatchdate', { params });
  }
};

// 存管相关
export const custodyApi = {
  // 存管充提FAQ文章链接 type: 0 充值 1 提现
  getFaqList(params: object) {
    return axios.get('@api/exchange/api/cfs/custody/faq/list', { params });
  },
  // 获取充值地址信息
  getDepositAddressInfo(params: object) {
    return axios.get('@api/exchange/api/cfs/deposit/addressInfo', { params });
  },
  // 获取最新的可用预充值记录
  getPersonDepositInfo(params: object) {
    return axios.post('@api/exchange/api/cfs/custody/address/whitelist/preDeposit/edit/getLastOneByEnable', params, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 白名单预充值一次提交
  getPreDeposit(params: object) {
    return axios.post('@api/exchange/api/cfs/custody/address/whitelist/preDeposit/edit', params, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 白名单预充值二次提交
  getPreDepositConfirm(params: object) {
    return axios.post('@api/exchange/api/cfs/custody/address/whitelist/preDeposit/confirm', params, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 交易平台列表
  getPlatformList(params: object) {
    return axios.get('@api/exchange/api/cfs/custody/address/thirdPartyList', { params });
  },
  // 获取充值 / 提现说明
  getDescription(data: object) {
    return axios.post('@api/exchange/api/cfs/baseConfig/getDescription', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 获取风险金额
  getRiskAmount() {
    return axios.get('@api/exchange/api/cfs/custodyAccount/getRiskAmount');
  },
  // 获取说明文案区分语言 - 存管充值时，新增地址充值地址来源时，个人钱包和交易所钱包充值说明
  getDescByLanguage(data: object) {
    return axios.post('@api/exchange/api/cfs/baseConfig/getDescriptionByLanguage', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 获取地址待签名信息
  getAddrSignMsg(params: object) {
    return axios.get('@api/exchange/api/cfs/custody/address/user/info', { params });
  },
  // 个人钱包类型地址充值认证(验签)
  verifyAddrSignMsg(data: object) {
    return axios.post('@api/exchange/api/cfs/custody/address/personalWallet/testify', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 获取链提示
  getChainTips(params: {
    network: string,
    assetId: string,
    bizType: 'withdraw' | 'deposit'
  }) {
    return axios.get('@api/exchange/api/cfs/custody/network/depositAndWithdraw/Tips', {
      params: params
    });
  },
  // 提现白名单
  getWithdrawWhite(params: object) {
    return axios.get('@api/exchange/api/cfs/withdraw/withdrawWhite', { params });
  },
  // 退款
  onRefund(data: object) {
    return axios.post('@api/exchange/api/cfs/custodyAccount/refundSubmitV2', data,  {
      headers: { 'Content-Type': 'application/json' }
    });
  }
};

// HSK 手续费相关
export const hskFeeApi = {
  // 获取用户vip信息
  getUserVipInfo(params: object) {
    return axios.get('@bapi/v3/vip/info', { params });
  },
  // 获取VIP手续费配置
  getHskFee() {
    return axios.get('@bapi/v3/vip/fee');
  },
  // 手动触发升级
  upgradeVip() {
    return axios.post('@bapi/v3/vip/upgrade', {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 查询平台优惠费率 params: {asset: 'BTC', pageNo: 1, pageSize: 10, tradType: 1 / 4}  tradType: 1: 现货 4: 合约
  getFeePreferential(params: object) {
    return axios.get('@bapi/v3/fee/preferential', { params });
  },
  // 查询特殊费率 params: {asset: 'BTC', pageNo: 1, pageSize: 10, tradType: 1 / 4}  tradType: 1: 现货 4: 合约
  getFeeSpecial(params: object) {
    return axios.get('@bapi/v3/fee/special', { params });
  },
  // 查询特殊阶梯费率 params: {asset: 'BTC', pageNo: 1, pageSize: 10, tradType: 1 / 4}  tradType: 1: 现货 4: 合约
  getFeeSpecialStep(params: object) {
    return axios.get('@bapi/v3/fee/step', { params });
  },
  // 第一次进入改变弹框状态
  confirmCardPush(params: object) {
    return axios.post('@bapi/v3/vip/card/confirm', params, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // BMEX-723 HKEX-7834 平台费率说明页的数据从OPM读取配置
  getFeeDescription(str: string) {
    return axios.get('@bapi/v1/basic/get_plat_fee_desc', {
      params: {
        confKey: 'cust.platfee.' + str
      }
    });
  },
  // 查询 HSK 抵扣比例
  getHskDiscount() {
    return axios.get('@bapi/v3/hsk-deduction/discount');
  },
  // 开启 / 关闭 HSK 抵扣   {hskDeductionSwitch: 0 / 1} number
  changeHskDeduction(params: object) {
    return axios.post('@bapi/v3/settings/hsk-deduction', params, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
};

// 待地址认证
export const $addressVerifyApi = {
  getAddressVerifyList() {
    return axios.get('@api/exchange/api/cfs/deposit/addressTestifyCount');
  },
  // 待认证地址，返回最后一笔待认证地址信息 -- new
  getAddressVerifyInfo() {
    return axios.get('@api/exchange/api/cfs/deposit/addressTestifyCountV1');
  }
};
// 反向获客接口
export const $acquisitionApi = {
  creatAcquisition(data:any) {
    return axios.post('@bapi/v1/activities/customer/acquisition', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
};

// 换汇接口
export const exchangeApi = {
  // 换汇记录
  getOrderList(params: object) {
    return axios.get('@api/exchange/api/cfs/fx/order/exchange/order/list', { params });
  },
  // 订单详情
  getOrderDetail(params: object) {
    return axios.get('@api/exchange/api/cfs/fx/order/exchange/order/detail', { params });
  },
  // 申请兑换
  onApplyExchange(data:any) {
    return axios.post('@api/exchange/api/cfs/fx/order/exchange', data, { headers: { 'Content-Type': 'application/json' } });
  },
  // 根据兑换方向获取信息
  getInfoByType(params: object) {
    return axios.get('@api/exchange/api/cfs/fx/service/info', { params });
  },
  // 获取换汇所有信息
  getExchangeInfo() {
    return axios.get('@api/exchange/api/cfs/fx/service/exchangeTypeInfoList');
  }
};

// vip专区接口
export const vipZoneApi = {
  // 获取活动状态
  getStatus() {
    return axios.get('@bapi/v1/activities/customer/vip/status');
  },
  // 个人提交
  individualSubmit() {
    return axios.post('@bapi/v1/activities/customer/person/vip');
  },
  // 企业表单提交
  corporateSubmit(data:any) {
    return axios.post('@bapi/v1/activities/customer/company/vip', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  }
};

// 护航计划接口
export const safeWayApi = {
  // 币种列表
  getTokenList() {
    return axios.get('@bapi/v1/learn/list');
  },
  // 币种详情
  getTokenDetail(params: any) {
    return axios.get('@bapi/v1/learn/detail', { params });
  }
};

// 返佣相关接口
export const inviteApi = {
  /**
   * 获取代理返佣-数据概览-返佣等级信息
   */
  getAgentLevelInfo() {
    return axios.get('@bapi/v1/invite/agent/personal/info');
  },
  /**
   * 获取代理返佣-分佣比例
   */
  getAgentRebateInfo() {
    return axios.get('@bapi/v1/invite/agent/rebate/shard/info');
  },
  // 修改返佣比例
  setAgentLevelInfo(data:any) {
    return axios.post('@bapi/v1/invite/agent/rebate/shard/update', data, {
      headers: { 'Content-Type': 'application/json' }
    });
  },
  // 获取代理返佣-数据概览-统计信息
  getAgentOverviewInfo(params: {dateType: 'yesterday' | 'before_7d' | 'before_30d' | undefined}) {
    return axios.get('@bapi/v1/invite/agent/overview/info', { params });
  },
  // 获取代理返佣-数据概览-统计图表信息
  getAgentOverviewChart(params: {type: number, dateType: 'before_7d' | 'before_30d'}) {
    return axios.get('@bapi/v1/invite/agent/overview/statement', { params });
  },
  // 获取代理返佣-一级邀请-统计信息
  getAgentLevel1Info() {
    return axios.get('@bapi/v1/invite/agent/level1/info');
  },
  // 获取代理返佣-一级邀请-table 数据
  getAgentLevel1Table(params: {pageNum: number, startTime?: number, endTime?: number, pageSize?: number}) {
    return axios.get('@bapi/v1/invite/agent/level1/search', { params });
  },
  // 获取代理返佣-二级邀请-统计信息
  getAgentLevel2Info() {
    return axios.get('@bapi/v1/invite/agent/level2/info');
  },
  // 获取代理返佣-二级邀请-table 数据
  getAgentLevel2Table(params: {pageNum: number, startTime?: number, endTime?: number, pageSize?: number}) {
    return axios.get('@bapi/v1/invite/agent/level2/search', { params });
  },
  // 获取代理返佣-佣金收入根据类型(现货/合约)汇总
  getAgentIncomeInfo(params: {type: 1 | 2}) {
    return axios.get('@bapi/v1/invite/agent/income/summary', { params });
  },
  // 获取代理返佣-佣金收入-现货 table 数据
  getAgentIncomeSpotTable(params: {startTime?: number, endTime?: number}) {
    return axios.get('@bapi/v1/invite/agent/income/coin/info', { params });
  },
  // 获取代理返佣-佣金收入-现货 table 数据
  getAgentIncomeSpotSourceTable(params: {pageNum: number, startTime?: number, endTime?: number, pageSize?: number, inviteType?: 0 | 1 | -1}) {
    return axios.get('@bapi/v1/invite/agent/income/coin/search', { params });
  },
  // 获取代理返佣-佣金收入-合约 table 数据
  getAgentIncomeOptionTable(params: {startTime?: number, endTime?: number}) {
    return axios.get('@bapi/v1/invite/agent/income/option/info', { params });
  },
  // 获取代理返佣-佣金收入-合约 table 数据
  getAgentIncomeOptionSourceTable(params: {pageNum: number, startTime?: number, endTime?: number, pageSize?: number, inviteType?: 0 | 1 | -1}) {
    return axios.get('@bapi/v1/invite/agent/income/option/search', { params });
  },
  // 代理返佣相关接口-同意代理商协议
  getAgentAgreement() {
    return axios.get<any, ApiResponse<{ agreement: number, [property: string]: any}>>('@bapi/v1/invite/agent/personal/info', {});
  },
  // 代理返佣相关接口-同意代理商协议
  setAgentAgreement() {
    return axios.post<any, ApiResponse<unknown>>('@bapi/v1/invite/agent/agreement', {});
  }
};

// 官方渠道验证
export const officialVerificationApi = {
  // 渠道列表
  getChannelList() {
    return axios.get('@bapi/v3/channel/all');
  },
  // 首页文案
  getDesc() {
    return axios.get('@bapi/v3/channel/desc');
  },
  // 验证
  channelCheck(data:any) {
    return axios.post('@bapi/v3/channel/check', data);
  }
};
